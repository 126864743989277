import React from "react";
import { FormattedMessage } from "react-intl";
import { themeGet } from "styled-system";

import {
  BackgroundBox,
  StyledF2,
  StyledF4,
  FormContainer,
  Error
} from "../components/Account/AccountFormStyles";

import { getCourseState } from "../services/quiznator";
import {
  createCertificate,
  checkCertificate,
  certificateUrl
} from "../services/certificates";

import TextF8 from "../components/TextF8";

//import { SubmitButton } from "./Account/AccountFormStyles";

import _ from "lodash";

import styled from "styled-components";

import { injectCourseProgress } from "moocfi-quizzes";

const availabilityStates = {
  IN_PROGRESS: "IN_PROGRESS",
  AVAILABLE: "AVAILABLE",
  NOT_YET_AVAILABLE: "NOT_YET_AVAILABLE",
  AVAILABILITY_CHECK_FAILED: "AVAILABILITY_CHECK_FAILED",
  GENERATION_FAILED: "GENERATION_FAILED",
  GENERATED: "GENERATED"
};

const StyledF8 = styled(TextF8)`
  color: white;
`;

const CenteredError = styled(Error)`
`;

const SubmitButton = styled.button`
   color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  background: #19ca94;
  border: 2px solid #19ca94;
  display: block;
  font-size: 0.875rem;
  border-radius: 90px;
  padding: 0.75rem 1.5rem;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.2s ease;
  text-align: center;
  cursor: pointer;
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  :hover {
    background: #11ab7c;
    border: 2px solid #11ab7c;
  }
  @media screen and (min-width: 38em) {
    padding: 1rem 3rem;
    font-size: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media screen and (min-width: 72em) {
    margin-top: 0;
  }
`;

//

class CertificateAvailability extends React.Component {
  constructor() {
    super();

    this.generateCertificate = this.generateCertificate.bind(this);
    this.downloadCertificate = this.downloadCertificate.bind(this);

    this.state = {
      isSubmitting: false,
      availabilityState: availabilityStates.IN_PROGRESS,
      certificateId: null
    };
  }

  componentDidMount() {
    const completed =
      this.props.courseProgressData && this.props.courseProgressData.completed;
    checkCertificateState(completed)
      .then(({ state, certificateId }) => {
        this.setState({
          availabilityState: state,
          certificateId
        });
      })
      .catch(err => {
        this.setState({
          availabilityState: availabilityStates.AVAILABILITY_CHECK_FAILED
        });
      });
  }

  generateCertificate(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isSubmitting: true
    });

    const email = this.props.user.email

    createCertificate(email)
      .then(res => {
        const certificateId = _.get(res, "id", null);
        this.setState({
          availabilityState: certificateId
            ? availabilityStates.GENERATED
            : availabilityStates.GENERATION_FAILED,
          certificateId
        });
      })
      .catch(() => {
        this.setState({
          availabilityState: availabilityStates.GENERATION_FAILED
        });
      });
  }

  downloadCertificate(e) {
    if (e) {
      e.preventDefault();
    }
    window.open(certificateUrl(this.state.certificateId), "_blank");
  }

  render() {
    const name = parseName(this.props);

    if (
      this.state.availabilityState ===
      availabilityStates.AVAILABILITY_CHECK_FAILED
    ) {
      return (
        <CenteredError>
          <FormattedMessage id="certificate.failedToCheck" />
        </CenteredError>
      );
    } else if (
      this.state.availabilityState === availabilityStates.GENERATION_FAILED
    ) {
      return (
        <CenteredError>
          <FormattedMessage id="certificate.failedToGenerate" />
        </CenteredError>
      );
    } else if (
      this.state.availabilityState === availabilityStates.NOT_YET_AVAILABLE
    ) {
      return (
        <StyledF8>
          <FormattedMessage id="certificate.notYetAvailable" />
        </StyledF8>
      );
    } else if (this.state.availabilityState === availabilityStates.AVAILABLE) {
      if (nameNotGiven(name)) {
        return (
          <StyledF8>
            <FormattedMessage id="certificate.updateName" />
          </StyledF8>
        );
      } else {
        return (
          <div>
            <StyledF8>
              <FormattedMessage id="certificate.generate" />
            </StyledF8>

            {this.props.user && (
              <StyledF8>
                <FormattedMessage id="certificate.generateTextPart1" /> "{name}
                ". <FormattedMessage id="certificate.generateTextPart2" />
              </StyledF8>
            )}
            <SubmitButton
              onClick={this.generateCertificate}
              disabled={this.state.isSubmitting}
            >
              <FormattedMessage id="certificate.generateButton" />
            </SubmitButton>
          </div>
        );
      }
    } else if (this.state.availabilityState === availabilityStates.GENERATED) {
      return (
        <SubmitButton onClick={this.downloadCertificate}>
          <FormattedMessage id="certificate.downloadButton" />
        </SubmitButton>
      );
    } else {
      // this.state.availabilityState === availabilityStates.IN_PROGRESS
      return (
        <StyledF8>
          <FormattedMessage id="certificate.checkingAvailability" />
        </StyledF8>
      );
    }
  }
}

const parseName = props => {
  let name = "";
  if (props.user) {
    name = `${props.user.user_field.first_name} ${props.user.user_field.last_name}`;
  }
  return name;
};

const nameNotGiven = name => name.trim().length === 0;

function checkCertificateState() {
  return Promise.all([checkCertificate()])
    .then(([certificateAvailability]) => {
      if (!certificateAvailability.completed_course) {
        return Promise.resolve({
          state: availabilityStates.NOT_YET_AVAILABLE,
          certificateId: null
        });
      }

      const certificateId = _.get(
        certificateAvailability,
        "existing_certificate",
        null
      );

      return Promise.resolve({
        state: certificateId
          ? availabilityStates.GENERATED
          : availabilityStates.AVAILABLE,
        certificateId
      });
    })
    .catch(err => Promise.reject(err));
}

export default injectCourseProgress(CertificateAvailability);
