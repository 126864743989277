import React from "react";
import { FormattedMessage } from "react-intl";

import { connect } from "react-redux";
import { themeGet } from "styled-system";
import TextF8 from "../TextF8";
import styled, { keyframes } from "styled-components";
import TextF5 from "../TextF5";
import TextF6 from "../TextF6";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import EditIcon from "./EditIcon";
import PercentageIcon from "./PercentageIcon";
import Box from "../Box";
import { ClipLoader } from "react-spinners";
import {
  getStats,
  isValidating,
  isInitializing
} from "../../state/stats/selectors";
import withAuthentication from "../Session/withAuthentication";
import { PropTypes } from "prop-types";
import { injectCourseProgress, RequiredAction } from "moocfi-quizzes";

const PendingReviewGlow = keyframes`
  0% { opacity: 1;}
  50% { opacity: 0.6;}
  75% { opacity: 1;}
`;

const StatusTitle = styled(TextF8)`
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0;
  margin-bottom: 1rem;
`;

const ExcercisesCompleted = styled(TextF2)`
  color: white;
  margin: 0;
  line-height: 1;
`;

const ExcercisesCompletedSmall = styled(TextF3)`
  color: white;
  margin: 0;
  line-height: 1;
`;

const ExcercisesTotal = styled(TextF5)`
  color: white;
  margin: 0;
  line-height: 1;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
  @media screen and (min-width: 38em) {
    margin-bottom: 0.45rem;
  }
`;

const PendingPeerReview = styled.p`
  color: white;
  display: block;
  position: relative;
  width: 144px;
  margin: 0.5rem auto 0;
  font-size: 0.875rem;
  padding-left: 16px;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  :before {
    content: "";
    display: block;
    background: rgb(255, 192, 127);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    animation: ${PendingReviewGlow} 1.5s infinite;
  }
`;

const ExcercisesTotalSmall = styled(TextF6)`
  color: white;
  margin: 0;
  line-height: 1;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  margin-bottom: 0.28rem;
  padding-left: 0.2rem;
`;

const StyledBox = styled(Box)`
  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    > div:first-of-type {
      padding-bottom: 2em;
    }
  }
`;

const Stats = (props, context) => {
  const { stats, small } = props;
  const { loggedIn } = context;

  if (!loggedIn) {
    return null;
  }

  const { loading, courseProgressData } = props;

  let qPoints = 0;
  let qTotal = 22;
  let totalCompleted = 0;
  let awaitingPeerReview = false;

  if (courseProgressData) {
    qPoints += courseProgressData.n_points || 0;
    const exercise_completions_by_section =
      courseProgressData.exercise_completions_by_section;
    for (const section of exercise_completions_by_section) {
      totalCompleted += Number.parseInt(section.exercises_completed, 10);
      if (
        section.required_actions.includes(RequiredAction.PENDING_PEER_REVIEW)
      ) {
        awaitingPeerReview = true;
      }
    }
  }

  let totalCount = "--";
  let percentageScore = "--";

  if (stats) {
    const {
      ignoredAmount,
      maxNormalizedPoints
    } = stats;

    totalCount = maxNormalizedPoints + ignoredAmount || "--";
    percentageScore = Math.floor((qPoints / qTotal) * 100);
  }

  return (
    <Box display="flex" justifyContent="center">
      {loading ? (
        <Box w={"480px"} display="flex" justifyContent="center">
          <ClipLoader color="white" size={small ? 40 : 80} />
        </Box>
      ) : (
        <StyledBox>
          <Box w={"240px"}>
            <StatusTitle>
              <FormattedMessage id="quiz.status.correctAnswers" />
            </StatusTitle>
            <Box className="flex">
              <Box display="flex" alignItems="flex-end" justifyContent="center">
                <PercentageIcon small={small} completed={percentageScore} />
                {small ? (
                  <React.Fragment>
                    <ExcercisesCompletedSmall>
                      {percentageScore}
                    </ExcercisesCompletedSmall>
                    <ExcercisesTotalSmall> %</ExcercisesTotalSmall>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ExcercisesCompleted>{percentageScore}</ExcercisesCompleted>
                    <ExcercisesTotal> %</ExcercisesTotal>
                  </React.Fragment>
                )}
              </Box>

              {awaitingPeerReview ? (
                <PendingPeerReview>
                  <FormattedMessage id="quiz.pendingPeerReview" />
                </PendingPeerReview>
              ) : null}
            </Box>
          </Box>
          <Box w={"240px"}>
            <StatusTitle>
              <FormattedMessage id="quiz.status.exercisesCompleted" />
            </StatusTitle>
            <Box className="flex">
              <Box display="flex" alignItems="flex-end" justifyContent="center">
                <EditIcon small={small} />
                {small ? (
                  <React.Fragment>
                    <ExcercisesCompletedSmall>
                      {totalCompleted}
                    </ExcercisesCompletedSmall>
                    <ExcercisesTotalSmall>
                      {" "}
                      /{/*totalCount*/ 25}
                    </ExcercisesTotalSmall>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ExcercisesCompleted>{totalCompleted}</ExcercisesCompleted>
                    <ExcercisesTotal> /{/*totalCount*/ 25}</ExcercisesTotal>
                  </React.Fragment>
                )}
              </Box>
            </Box>
          </Box>
        </StyledBox>
      )}
    </Box>
  );
};

Stats.contextTypes = {
  loggedIn: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    stats: getStats(state),
    validating: isValidating(state),
    initializing: isInitializing(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(withAuthentication(injectCourseProgress(Stats)));
