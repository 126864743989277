import TmcClient from "tmc-client-js";
import fetchPonyfill from "fetch-ponyfill";
import _ from "lodash";
import axios from "axios";
import { config } from "../state/course-config";

import * as accountState from "../state/account";
import { updateMailchimpSubscriber } from './zapier.js'
const { fetch } = fetchPonyfill();

const BASE_URL = "https://certificates.mooc.fi";
const { LOCALIZED_COURSE_ID } = config;

const tmcClient = new TmcClient(
  "59a09eef080463f90f8c2f29fbf63014167d13580e1de3562e57b9e6e4515182",
  "2ddf92a15a31f87c1aabb712b7cfd1b88f3465465ec475811ccce6febb1bad28"
);

export function createCertificate(email) {
  return axios
    .post(`${BASE_URL}/create/${LOCALIZED_COURSE_ID}`, undefined, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken()}`
      }
    })
    .then(res => {
      updateMailchimpSubscriber(email)
      return res.data;
    })
    .catch(error => {
      console.log("error", error);
      return Promise.reject(error.response);
    });
}

export function checkCertificate() {
  return axios
    .get(`${BASE_URL}/certificate-availability/${LOCALIZED_COURSE_ID}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken()}`
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      const status = _.get(error, "response.status", null);
      if (status === 403) {
        return {};
      }
      return Promise.reject(error.response);
    });
}

export const certificateUrl = id => `${BASE_URL}/validate/${id}`;

function accessToken() {
  return _.get(accountState.getUser(), "accessToken", null);
}
