import React from "react";

export const Part1Svg = props => {
  return (
    <svg
      width="390"
      height="271"
      viewBox="0 0 390 271"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M2.153 20.56C.722 26.774 0 33.133 0 39.513v23.372h42.39V7.416L6.885 0 2.153 20.56z"
          id="a1"
        />
        <path
          d="M33.71 14C21.883 14 9.736 9.263.523 1.601c10.075 19.672 12.417 26.75 11.36 28.93-1.285 2.646-3.854 3.307-3.854 3.307.639.266 1.25.608 1.808 1.048 3.974 3.137 13.168 7.999 30.296 5.565 12.101-1.72 16.88-7.071 18.743-10.607.656-1.244 1.68-2.205 2.889-2.797-2.71-2.058-5.369-4.606-4.298-7.758 1.658-4.879 3.9-11.827 4.45-18.653-6.345 8.223-15.89 13.362-28.205 13.362z"
          id="c1"
        />
        <path
          d="M43.614.952C17.22 3.352-2.21 26.456.216 52.556c2.424 26.1 26.254 50.308 52.65 47.91 26.394-2.4 45.362-30.498 42.935-56.598C93.51 19.244 72.587.75 48.063.75c-1.47 0-2.955.067-4.449.202z"
          id="e1"
        />
        <path
          d="M126.995 15.611c-1.189.09-1.644.143-2.326.476-1.372.679-2.01 1.135-2.597 2.243-1.94 3.543-8.609 9.501-19.42 11.03-15.298 2.165-23.51-2.16-27.06-4.95-.499-.391-1.043-.696-1.613-.932-1.382-.573-2.917-.642-4.41-.35C8.184 35.179 0 125.248 0 125.248l46.182 11.609s-2.073 29.263-1.215 44.203c1.11 19.386-1.86 41.848-2.442 56.094-.32 7.8 5.612 8.606 6.22 13.393 1.355-.663 2.761-1.304 4.201-1.93 38.212-16.599 105.156-20.445 115.657-21.012 10.895-.588.573-23.53-1.148-43.531-1.72-20.001 1.721-62.357 5.735-81.769 3.53-17.074 30.129-39.151 36.487-44.225.826-.659 1.153-1.776.819-2.794-1.137-3.455-3.733-11.236-4.618-15.28-1.146-7.703-2.876-11.167-4.587-17.116-2.61-9.065-4.4-18.12-6.071-21.395-.28-.548-.54-1.045-.781-1.494-6.137 3.494-17.617 7.203-34.438 11.124-17.695 4.125-33.235 4.487-33.006 4.487z"
          id="g1"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(91.532 209.53)">
          <mask id="b1" fill="#fff">
            <use xlinkHref="#a1" />
          </mask>
          <path
            fill="#FFC2C2"
            mask="url(#b1)"
            d="M-4.158 67.078h50.706v-71.27H-4.158z"
          />
        </g>
        <g transform="translate(149.617 80.983)">
          <mask id="d1" fill="#fff">
            <use xlinkHref="#c1" />
          </mask>
          <path
            fill="#FFC2C2"
            mask="url(#d1)"
            d="M.836 44.244h64.11V-.832H.836z"
          />
        </g>
        <g transform="translate(129.943 1)">
          <mask id="f1" fill="#fff">
            <use xlinkHref="#e1" />
          </mask>
          <path fill="#342D71" mask="url(#f1)" d="M-3 104.485h99.118V.89H-3z" />
        </g>
        <path
          d="M192.393 53.74c.411 1.115 1.525 1.835 2.67 2.128 1.143.293 2.342.236 3.521.262 4.031.089 8.036 1.185 11.558 3.164 1.64.922 3.21 2.068 4.245 3.648.203.31.426.77.622 1.298.644 1.731.992 4.231-.654 4.89-1.325.225-2.666-.048-3.41-.126-1.218-.126-2.432-.382-3.543-.921-2.594-1.262-4.392-3.935-7.614-4.04-1.402-.047-2.724.836-3.05 2.211-.589 2.471 1.807 2.29 2.722 4.54.372.915 1.042 2.02 1.485 2.904.881 1.76 2.966 2.95 4.891 3.285l4.032.702 3.771.656c2.212.385 5.796.209 7.94-.458 3.214-.999 5.449-2.66 8.617-3.784 1.244-.44 2.562-.651 3.881-.64 7.426.056 14.337 1.26 21.753 1.478 11.857.35 24.203.424 36.356 5.093 0 0-6.955 4.593-11.71 8.151.084.156-5.083 10.805-4.66 11.62 3.095 3.954-2.028 10.328 0 18.675 1.726 5.9 14.276 4.838 13.888 10.504 0 .02 2.315-1.13 2.315-1.11 2.382-1.941 19.67-16.013 38.815-31.343 4.48-3.588 8.315-7.948 11.177-12.944 2.76-4.817 4.884-10.57 2.96-15.005-4.051-9.337-20.833-16.34-59.029-16.34-38.194 0-46.295.584-57.87-10.505-4.444-4.258-8.55-8.383-15.117-9.11-2.241-.156-3.058.414-5.024 1.505-1.322.735-3.388.707-6.72 1.311-.715.13-1.818.548-2.465.88-.197.1-.398.2-.607.297-2.667 1.253-3.356 1.413-4.778 4.315-.595 1.218-.842 2.933-1.517 4.583-.058.138-.682 2.012-.651 2.158.173 1.065 1.798 3.529 5.637-.448.72-.87 1.382-2.495 2.474-3.02 1.002-.482 2.716-.8 3.826-.823 3.724-.079 6.85 1.981 9.955 4.06.504.338 1.023.773 1.348 1.28.338.525-.128 1.196-.734 1.043-2.677-.677-5.322-1.394-8.028-1.77-2.197-.305-4.446-.372-6.635-.058 0 0-5.384.515-6.522 3.507-.313.734-.395 1.554-.12 2.296z"
          fill="#FFA9C7"
        />
        <path
          d="M192.514 51.443c-.313.734-.395 1.554-.12 2.296.41 1.116 1.524 1.836 2.669 2.13 1.143.292 2.342.235 3.521.26 4.031.09 8.036 1.186 11.558 3.165 1.64.922 3.21 2.068 4.245 3.648.203.31.426.77.622 1.298.644 1.731.992 4.231-.654 4.89-1.325.225-2.666-.048-3.41-.126-1.218-.126-2.432-.382-3.543-.921-2.594-1.262-4.392-3.935-7.614-4.04-1.402-.047-2.724.836-3.05 2.211-.589 2.471 1.807 2.29 2.722 4.54.372.915 1.042 2.02 1.485 2.904.881 1.76 2.966 2.95 4.891 3.285l4.032.702 3.771.656c2.212.385 5.796.209 7.94-.458 3.214-.999 5.449-2.66 8.617-3.784 1.244-.44 2.562-.651 3.881-.64 7.426.056 14.337 1.26 21.753 1.478 11.857.35 24.203.424 36.356 5.093 0 0-6.955 4.593-11.71 8.151.084.156-5.083 10.805-4.66 11.62 3.095 3.954-2.028 10.328 0 18.675 1.726 5.9 14.276 4.838 13.888 10.504 0 .02 2.315-1.13 2.315-1.11 2.382-1.941 19.67-16.013 38.815-31.343 4.48-3.588 8.315-7.948 11.177-12.944 2.76-4.817 4.884-10.57 2.96-15.005-4.051-9.337-20.833-16.34-59.029-16.34-38.194 0-46.295.584-57.87-10.505-4.444-4.258-8.55-8.383-15.117-9.11-2.241-.156-3.058.414-5.024 1.505-1.322.735-3.388.707-6.72 1.311-.715.13-1.818.548-2.465.88-.197.1-.398.2-.607.297-2.667 1.253-3.356 1.413-4.778 4.315-.595 1.218-.842 2.933-1.517 4.583-.058.138-.682 2.012-.651 2.158.173 1.065 1.798 3.529 5.637-.448.72-.87 1.382-2.495 2.474-3.02 1.002-.482 2.716-.8 3.826-.823 3.724-.079 6.85 1.981 9.955 4.06.504.338 1.023.773 1.348 1.28.338.525-.128 1.196-.734 1.043-2.677-.677-5.322-1.394-8.028-1.77-2.197-.305-4.446-.372-6.635-.058 0 0-5.384.515-6.522 3.507"
          fill="#FFC2C2"
        />
        <path
          d="M200.646 68.168l-.827-.898c-.814-.885-2.193-.942-3.078-.129-.027.026-.047.056-.072.082.192 1.498 2.017 1.668 2.791 3.57.11.273.251.563.401.858.235-.095.46-.226.658-.408.885-.814.942-2.19.127-3.075M192.742 47.13l.721-1.645c.244-.556-.01-1.204-.567-1.447-.217-.096-.302-.119-.518-.073-.074.504-.326 1.04-.535 1.55-.054.13-.624 1.844-.644 2.114.034.02.057.05.093.066.557.243 1.206-.01 1.45-.565M196.42 48.573c-.996.384-3.688 1.16-3.303 3.64.22.633.619 1.003 1.04.883l2.115-.604c.58-.166.979-1.192.892-2.294-.062-.795-.364-1.407-.745-1.625z"
          fill="#FFF"
          opacity="0.65"
        />
        <path
          d="M207.09 43.706c-1.168.75-2.747 1.571-3.589 1.571-1.648 0-2.982-1.334-2.982-2.979s1.334-2.979 2.982-2.979c1.647 0 2.983 1.334 2.983 2.98 0 .803 1.232 1.003.605 1.407z"
          fill="#FFC2C2"
        />
        <g transform="translate(86 88.382)">
          <mask id="h1" fill="#fff">
            <use xlinkHref="#g1" />
          </mask>
          <path
            fill="#5752CD"
            mask="url(#h1)"
            d="M-4.108 254.668h218.841V-4.122H-4.107z"
          />
        </g>
      </g>
    </svg>
  );
};

export const Part2Svg = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 390 253"
      width="389"
      height="267"
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g strokeLinejoin="round" strokeWidth="15">
          <g stroke="#FFC2C2" className="take-me-up">
            <path d="M172.98243453 413.88399463c33.66406994-22.24144545 63.75395838-49.80552963 88.7888931-81.33617704 14.31615484-18.02952663 27.34016457-38.26404314 30.17268357-61.0540459 3.02194303-24.31182662-5.86756017-48.36942094-14.82770027-71.19495338-11.37309926-28.9717825-23.32797155-59.79778873-17.42922747-90.3329401 3.68079811-19.05498329 14.17265296-36.28750567 27.15207061-50.78775376 12.97942543-14.50069971 28.44327276-26.558304 43.81557613-38.53167251" />
            <path d="M305.36455562 8.10205924l39.92799128-.25916504-8.13964792 39.01388712" />
          </g>
          <path
            className="take-me-down"
            stroke="#1478BE"
            d="M424 238.5c0 58.818025-47.681522 106.5-106.500292 106.5C258.682106 345 211 297.318025 211 238.5c0-58.8180246 47.682106-106.5 106.499708-106.5C376.318478 132 424 179.6819754 424 238.5z"
          />
          <path
            className="take-me-down"
            stroke="#D2D8FF"
            d="M-25.55079996 135.7593548c17.27075771-7.23546799 37.36338304-7.40609449 54.77327867-.46445913 17.4102238 6.9418831 31.9367496 20.9148393 39.60035894 38.0919828 2.2782033 5.10687757 3.98736864 10.58072887 3.96681647 16.17408585-.0207997 5.59368673-1.9378604 11.341199-6.00966221 15.14862783-5.49202614 5.13526879-14.70708702 5.71300659-20.70167086 1.16323828-5.99458386-4.54976841-7.9325101-13.7726238-4.12887067-20.27108447 3.59278423-6.13846635 10.99274395-8.97892817 18.03669316-9.7842427 20.68811298-2.36419905 41.17827245 9.4783814 53.75730556 26.17619735 12.5790331 16.69781595 18.35812034 37.58028198 21.59773625 58.2595093"
          />
        </g>
        <path
          className="rotate-me"
          stroke="#4844A3"
          strokeWidth="13"
          d="M161.25925484 71.34251116l-27.98452795 50.98003128m38.85492786-10.41114654l-49.72532776-30.1577382"
        />
      </g>
    </svg>
  );
};

export const Part3Svg = props => {
  return (
    <svg
      width="390"
      height="250"
      viewBox="0 0 390 250"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h150.953v17.918H0z" />
        <path id="c" d="M.325.447h21.721v33.385H.325z" />
        <path id="e" d="M0 .481h152.816v87.426H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M349.194 151H85M382.351 126H202"
          stroke="#B1C6FF"
          strokeWidth="3"
          strokeLinecap="round"
          className="lines"
        />
        <circle cx="42" cy="42" r="42" fill="#FFC07F" className="sun" />
        <path
          d="M197.494 143.47c-.663-2.314-1.175-4.336-1.462-5.882-1.494-8.063-3.373-18.392 4.698-20.308 8.072-1.917 21.5-3.634 27.73 6.606 6.131 10.079 7.791 17.38 7.791 17.38s.107.332.283.937M168.541 145.728c1.485.01 2.768-1.042 3.059-2.493 5.284-26.372 4.024-24.936-22.216-24.71-15.66.135-20.7 13.418-22.42 17.602-.63 1.533-1.696 4.12-2.786 7.193-.367 1.032.402 2.119 1.5 2.126l42.863.282z"
          fill="#3299F3"
          opacity="0.5"
        />
        <g opacity="0.5" transform="translate(103 188)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            className="shaddow"
            d="M149.737 7.958c-74 10.792-85.272 12.02-138.28 7.463C6.84 15.024 1.185 14.218.141 11.348c-.708-1.95 1.316-3.968 4.01-5.01 2.695-1.042 5.927-1.327 9.056-1.589 39.749-3.332 80.035-6.667 119.804-3.431 4.326.352 8.772.812 12.481 2.267 3.71 1.455 6.001-.114 5.35 2.622l-1.106 1.752"
            fill="#342D71"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#ACB2E5"
          d="M223.271 160.144l-53.575 2.298 11.645 29.659 22.01.665 3.186-1.493z"
        />
        <g className="car">
          <path
            d="M75.066 62.728c.225.007.236.006.424.013 1.012.034 3.644.142 5.278.19-.91.088-1.794.348-2.637.758-4.342 2.112-5.19 8.458-5.411 15.79-.268 8.906 1.633 16.104 7.329 16.757-1.518-.116-7.924-.623-7.924-.623-5.975-.487-9.996-7.935-9.242-17.126.754-9.19 6.191-15.954 12.183-15.76z"
            fill="#342D71"
          />
          <g transform="translate(69.969 62.452)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <path
              d="M.337 16.816C.557 9.482 3.82 3.349 8.163 1.237 9.006.828 9.889.567 10.8.48c.293-.028.589-.038.887-.03 5.992.18 10.624 7.796 10.348 17.014-.278 9.217-5.36 16.545-11.351 16.366-.203-.007-.404-.023-.604-.046C4.385 33.13.07 25.72.337 16.816"
              fill="#342D71"
              mask="url(#d)"
            />
            <path
              fill="#342D71"
              mask="url(#d)"
              d="M9.132 33.632L1.27 32.85l-5.656-13.863L1.27 4.85 6.53.653h6.036L11.547 32.85z"
            />
          </g>
          <path
            d="M79.32 69.94c.512-.25 1.048-.407 1.6-.46.177-.017.358-.024.539-.018 3.636.108 6.448 4.732 6.28 10.326-.168 5.595-3.253 10.041-6.889 9.933-.123-.004-.245-.013-.367-.027-3.456-.397-6.076-4.894-5.913-10.3.133-4.451 2.114-8.173 4.75-9.454z"
            fill="#CFD3F9"
          />
          <path
            d="M10.153 60.112c.31-.004 8.023-.337 8.023-.337l2.846 4.513-4.722 5.895-6.645-2.22.498-7.851z"
            fill="#94A7CA"
          />
          <path
            d="M20.104 58.77c1.086.097 2.11.439 3.059.984-4.357 2.082-7.663 8.19-7.936 15.523-.331 8.903 3.932 16.343 9.623 17.036-1.517-.126-7.92-.678-7.92-.678-5.971-.53-9.938-8.005-9.12-17.19.819-9.185 6.323-16.203 12.294-15.675z"
            fill="#342D71"
          />
          <path
            d="M22.55 59.7c1.12-.535 2.306-.807 3.53-.763 5.991.222 10.57 7.871 10.227 17.086-.342 9.216-5.476 16.507-11.466 16.285-.203-.007-.403-.025-.603-.049-5.691-.693-9.954-8.133-9.623-17.036.272-7.332 3.579-13.442 7.935-15.523z"
            fill="#342D71"
          />
          <path
            fill="#ACB2E5"
            d="M152.373 67.994l-3.753-17.079-36.094 1.176 1.39 32.021 16.832-1.191 20.3-5.972z"
          />
          <path
            d="M135.33 55.068c1.188.097 2.306.488 3.329 1.12-4.478 1.982-7.875 8.22-8.102 15.708-.265 8.786 3.353 16.005 8.935 16.83-1.623-.18-5.787-.627-6.999-.769-.036-.002-.073 0-.11-.004-5.974-.488-9.992-7.936-9.237-17.127.755-9.191 6.211-16.246 12.185-15.758z"
            fill="#342D71"
          />
          <path
            d="M137.6 56.184c1.046-.456 2.15-.685 3.286-.652 6.054.18 10.733 7.762 10.452 16.936-.282 9.174-5.419 16.466-11.472 16.286-.284-.009-.563-.038-.841-.078-5.64-.82-9.88-8.114-9.61-16.857.229-7.452 3.66-13.663 8.186-15.635z"
            fill="#342D71"
          />
          <path
            d="M139.245 63.83c.445-.216.911-.354 1.391-.4.155-.014.312-.02.47-.015 3.16.095 5.605 4.114 5.459 8.978-.146 4.864-2.828 8.73-5.99 8.635-.107-.002-.213-.011-.318-.023-3.006-.344-5.283-4.255-5.142-8.954.116-3.87 1.838-7.106 4.13-8.22z"
            fill="#CFD3F9"
          />
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            d="M96.708 1.363s28.193 2.029 44.785 32.912c16.592 30.884 10.053 42.097 10.053 42.097s-.1.214-.499.576c.439-6.23.934-23.656-7.182-24.244-9.356-.678-12.774 27.206-13.116 30.217-6.192.873-14.205 1.621-24.584 2.069-5.244.226-10.179.488-14.856.771 1.162-2.505 4.075-10.351.171-19.748-4.51-10.858-12.732-8.632-12.732-8.632s-7.642 1.285-12.608 21.722c-.418 1.72-.953 3.233-1.56 4.567-1.177 2.584-3.782 4.36-6.625 4.23-17.826-.807-32.03-.639-47.47-1.839-21.355-1.66-4.193-43.224.165-53.829 4.238-10.31 8.337-36.911 86.058-30.869zM85.494 36.47l39.04-1.267c-.176-.605-.283-.936-.283-.936s-1.66-7.302-7.791-17.38c-6.23-10.24-19.658-8.524-27.73-6.607-8.071 1.916-6.192 12.245-4.698 20.308.287 1.546.799 3.568 1.462 5.882zm-28.953 2.258c1.485.01 2.768-1.042 3.059-2.493 5.284-26.372 4.024-24.936-22.216-24.71-15.66.135-20.7 13.418-22.42 17.602-.63 1.533-1.696 4.12-2.786 7.193-.367 1.032.402 2.119 1.5 2.126l42.863.282z"
            fill="#F2F4FF"
            mask="url(#f)"
          />
          <path
            d="M8.583 37.348c.53.661 1.267 1.08 2.383 1.08l5.744.05-.328-6.85c0-2.809-.985-4.864-3.474-5.236-.94 2.224-1.6 4.237-2.263 5.852-.53 1.286-1.248 3.034-2.062 5.104M62.186 26.303c-3.117 0-5.645 2.518-5.645 5.625v6.8h5.416c2.702 0 5.024-1.909 5.54-4.551l.23-1.174c.678-3.473-1.991-6.7-5.541-6.7"
            fill="#FFC07F"
            mask="url(#f)"
          />
          <path
            d="M124.479 33.899c.055.202-47.02-1.384-47.02-1.384s.61 10.316 1.832 10.576c1.221.26 27.618 3.554 31.827 3.612 4.209.057 23.142-7.062 22.775-7.094-.366-.032-9.414-5.71-9.414-5.71"
            fill="#F2F4FF"
            mask="url(#f)"
          />
          <path
            d="M95.437 29.63c-3 0-5.442 2.433-5.442 5.423v2.834c0 2.991 2.442 5.425 5.442 5.425s5.442-2.434 5.442-5.425v-2.834c0-2.99-2.442-5.424-5.442-5.424m0 15.764c-4.152 0-7.53-3.367-7.53-7.506v-2.834c0-4.138 3.378-7.505 7.53-7.505s7.53 3.367 7.53 7.505v2.834c0 4.14-3.378 7.506-7.53 7.506"
            fill="#261D7B"
            mask="url(#f)"
          />
          <path
            d="M124.479 35.002l-38.981 1.265c2.79 9.682 8.286 24.669 10.868 30.794 2.663 6.314 5.864 8.766 17.899 7.744 11.592-.983 11.605-5.116 12.482-11.19 1.639-11.337-1.113-24.613-2.268-28.613"
            fill="#CFD3F9"
            mask="url(#f)"
          />
        </g>
      </g>
    </svg>
  );
};

export const Part5Svg = props => {
  return (
    <svg
      width="390"
      height="250"
      viewBox="0 0 390 250"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a5" d="M0 0h390v220H0z" />
        <path id="c5" d="M0 0h390v260H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 33)">
          <mask id="b5" fill="#fff">
            <use xlinkHref="#a5" />
          </mask>
          <g mask="url(#b5)">
            <g transform="translate(0 -42)">
              <mask id="d" fill="#fff">
                <use xlinkHref="#c5" />
              </mask>
              <g mask="url(#d5)">
                <path
                  stroke="#C9FFF4"
                  strokeWidth="9"
                  d="M125.89 284.344l32.512-72.621 13.075-19.145L216.12 55l134.012 129.88 8.844-109.034L232.734 55l-44.015 5.566L46 120.98l51.844 163.364h168.13l-89.7-72.621 6.71-10.266 159.059-14.285h2.904l-34.54 91.668h111.052l-71.326-91.668"
                />
                <path d="M358 71.133V22.268" stroke="#C9FFF4" strokeWidth="8" />
                <path d="M358 74.5h38.398" stroke="#C9FFF4" strokeWidth="9" />
                <path
                  d="M-20.563 76.8l176.645 117.204M47 113.34L75.462-5M48.355 117L-25 232.102"
                  stroke="#C9FFF4"
                  strokeWidth="9"
                />
              </g>
              <circle
                className="circle circle-5"
                fill="#2CE6FB"
                mask="url(#d5)"
                cx="164"
                cy="200"
                r="47"
              />
              <circle
                className="circle circle-4"
                fill="#1274BF"
                mask="url(#d5)"
                cx="45.5"
                cy="122.5"
                r="39.5"
              />
              <circle
                className="circle circle-3"
                fill="#1274BF"
                mask="url(#d5)"
                cx="356.5"
                cy="74.5"
                r="20.5"
              />
              <circle
                className="circle circle-1"
                fill="#1274BF"
                mask="url(#d5)"
                cx="345"
                cy="187"
                r="33"
              />
            </g>
          </g>
        </g>
        <circle
          className="circle circle-2"
          fill="#1274BF"
          cx="213.5"
          cy="43.5"
          r="35.5"
        />
      </g>
    </svg>
  );
};

export const Part4Svg = props => {
  return (
    <svg viewBox="0 0 390 250" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(68 53)">
          <path
            transform="rotate(1 82 2174.462)"
            d="m11.711 194.43l103.44 14.37c2.2148 2.734 2.5654 2.3811 1.0516-1.0585-0.22792-0.51788-2.2416 0.76811-6.041 3.858 0.028511 1.6214-6.5096-50.657-12.993-67.346-6.4836-16.689-16.096-28.756-16.096-28.756-15.488 5.8489-20.462-6.3432-39.849-7.6605-19.387-1.3173-7.9982 5.1238-18.255 0.31865 0.019586 0.12397-9.0224 8.3858-8.9958 8.5131 13.809 4.2796 4.7828 15.744 7.3432 28.908l-9.6039 48.853z"
            fill="#D2D8FF"
          />
          <path
            transform="scale(-1 1) rotate(-8 90.13 1901.281)"
            d="m121.14 57.954c0 8.3701-4.2969 15.155-9.5989 15.155-5.301 0-9.5979-6.7848-9.5979-15.155 0-8.3701 4.2969-15.155 9.5979-15.155 5.3019 0 9.5989 6.7848 9.5989 15.155"
            fill="#4844A3"
          />
          <path
            transform="scale(-1 1) rotate(-8 38.343 1160.693)"
            d="m117.07 57.286c0-31.468-25.66-56.978-57.314-56.978-23.146 0-43.088 13.64-52.136 33.275-3.3256 7.2173-5.1794 15.245-5.1794 23.702 0 7.873 1.6069 15.372 4.5123 22.192 4.7721 11.201 13.047 20.57 23.458 26.752 8.5846 5.0982 18.619 8.0349 29.345 8.0349 10.92 0 21.128-3.0391 29.814-8.3096 16.49-10.005 27.5-28.058 27.5-48.669z"
            fill="#D2D8FF"
          />
          <path
            transform="scale(1 -1) rotate(-12 -232.144 -551.036)"
            d="m0.17826 8.9413l-0.048737-3.5839c-0.03624-2.7099 2.1199-4.9363 4.8166-4.9731 2.6968-0.036835 4.9125 2.1301 4.9491 4.84-0.26355 2.6996-0.39532 4.0494-0.39532 4.0494s-0.59937 4.4561-4.3726 4.5077c-3.6727 0.050164-4.9125-2.1301-4.9491-4.84z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1.8"
          />
          <path
            transform="rotate(-175 61.923 28.194)"
            d="m2.8394 8.8052l-0.033816-2.4867c-0.025145-1.8802 1.4709-3.425 3.342-3.4506 1.8712-0.025558 3.4085 1.478 3.4339 3.3582-0.18286 1.8731-0.27429 2.8096-0.27429 2.8096s-0.41587 3.0919-3.0339 3.1276c-2.5483 0.034806-3.4085-1.478-3.4339-3.3582z"
            fill="#342D71"
          />
          <path
            transform="scale(1 -1) rotate(-15 -169.66 -338.712)"
            d="m0.17826 8.9413l-0.048737-3.5839c-0.03624-2.7099 2.1199-4.9363 4.8166-4.9731 2.6968-0.036835 4.9125 2.1301 4.9491 4.84-0.26355 2.6996-0.39532 4.0494-0.39532 4.0494s-0.59937 4.4561-4.3726 4.5077c-3.6727 0.050164-4.9125-2.1301-4.9491-4.84z"
            fill="#fff"
            stroke="#fff"
            strokeWidth="1.8"
          />
          <path
            transform="rotate(-172 70.34 44.668)"
            d="m48.596 43.807l-0.03334-2.4516c-0.024791-1.8538 1.4501-3.3768 3.2949-3.402 1.8448-0.025198 3.3605 1.4572 3.3855 3.3109-0.18029 1.8467-0.27043 2.77-0.27043 2.77s-0.41001 3.0483-2.9912 3.0836c-2.5124 0.034316-3.3605-1.4572-3.3855-3.3109z"
            fill="#342D71"
          />
          <path
            transform="scale(-1 1) rotate(-8 -14.376 406.775)"
            d="m7.0336 72.572c-3.4572 0-6.2595-6.7848-6.2595-15.155 0-8.3701 2.8024-15.155 6.2595-15.155s6.2601 6.7848 6.2601 15.155c0 8.3701-2.803 15.155-6.2601 15.155z"
            fill="#4844A3"
          />
          <path
            transform="scale(-1 1) rotate(-8 -17.733 358.767)"
            d="m3.6767 66.597c-2.0307 0-3.6767-3.9853-3.6767-8.9017s1.6461-8.9017 3.6767-8.9017c2.0307 0 3.6771 3.9853 3.6771 8.9017s-1.6464 8.9017-3.6771 8.9017z"
            fill="#fff"
          />
          <path
            d="m90.431 135.2c0 12.236-0.9575 21.822-2.8725 28.758-1.915 6.9365-7.7679 17.912-17.559 32.927l48.917-8.7853c2.55-20.187 2.55-30.696 0-31.525-2.55-0.82922-8.5511-8.2855-18.003-22.369l-10.482 0.99339z"
            opacity="0.283"
            transform="matrix(-1 0 0 1 164 0)"
          />
          <path
            d="m81.93 125.85c3.0301-0.48462 4.5452 1.6696 4.5452 6.4625 0 5.2395 0.20258 17.161-0.6084 29.817-0.67643 10.557-6.1683 20.594-16.476 30.112l36.999-7.877c5.778-13.993 8.1598-21.924 7.1455-23.794-1.5215-2.8047-10.417-28.78-11.586-29.552-0.7793-0.51432-4.0771-2.7847-9.8933-6.8112-2.4763 0.62137-4.3367 1.0159-5.5813 1.1836-1.2445 0.16769-2.7596 0.32052-4.5452 0.4585z"
            fill="#A9ACE0"
            transform="matrix(-1 0 0 1 164 0)"
          />
          <path
            transform="translate(23.882)"
            d="m46.119 115c2.3941 3.6204 4.0015 7.0985 4.822 10.434 0 0.27344 24.625 4.3939 43.059-8.4712-4.1243 1.729-27.896 9.3198-41.55 3.7614-2.8395-1.6438-4.95-3.5519-6.3313-5.7242z"
            fill="#A9ACE0"
          />
          <rect
            transform="rotate(21 41.263 155.2)"
            x="17.011"
            y="103.24"
            width="48.504"
            height="103.93"
            rx="24.252"
            fill="#4844A3"
          />
          <path
            transform="rotate(-69 67.535 196.2)"
            d="m68.346 137.54c12.287 0 22.248 9.9609 22.248 22.248 0 0.49524-0.016536 0.99034-0.049579 1.4845-1.4904 22.288-2.2356 37.499-2.2356 45.632 0 6.3647 0.4563 14.562 1.3689 24.593h2.1e-6c1.0719 11.781-7.6098 22.201-19.391 23.273-0.64529 0.058711-1.2929 0.088112-1.9409 0.088112-13.183 0-23.871-10.687-23.871-23.871v-69.578c0-13.183 10.687-23.871 23.871-23.871z"
            fill="#B0CCFC"
          />
          <path
            transform="rotate(15 55.448 162.77)"
            d="m59.235 114.07c4.9222 4.4387 8.339 10.569 8.339 17.719v55.425c0 13.394-10.858 24.252-24.252 24.252"
            fill="#4844A3"
          />
          <path d="m75.288 123.35c1.1999 4.6712 0.22103 12.931-2.9365 24.779-3.1576 11.848-5.7161 21.125-7.6758 27.83" />
          <path
            transform="rotate(-69 78.841 200.4)"
            d="m79.524 129.68c12.43 0 22.506 10.076 22.506 22.506 0 0.4243-0.011999 0.84852-0.035983 1.2721-1.6711 29.516-2.5066 49.466-2.5066 59.85 0 8.6321 0.57745 20.061 1.7323 34.286l6e-6 -1e-6c0.9728 11.982-7.952 22.484-19.934 23.457-0.58593 0.04757-1.1736 0.071385-1.7614 0.071385-13.183 0-23.871-10.687-23.871-23.871v-93.701c0-13.183 10.687-23.871 23.871-23.871z"
            fill="#4844A3"
          />
          <ellipse
            cx="36.376"
            cy="180.28"
            rx="30.391"
            ry="30.284"
            fill="#D2D8FF"
          />
        </g>
        <g transform="translate(233 5)" fill="#fff">
          <ellipse
            className="bubble-1"
            cx="73.263"
            cy="42"
            rx="42.737"
            ry="42"
            opacity="0"
          />
          <ellipse
            className="bubble-2"
            cx="15.263"
            cy="69"
            rx="15.263"
            ry="15"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export const Part6Svg = props => {
  return (
    <svg
      width="390"
      height="250"
      viewBox="0 0 390 250"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <radialGradient
          cx="46.837%"
          cy="47.56%"
          fx="46.837%"
          fy="47.56%"
          r="95.205%"
          gradientTransform="scale(-1 -.99432) rotate(-9.512 -5.733 5.628)"
          id="a"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g transform="translate(53 12)" fill="none" fillRule="evenodd">
        <path
          d="M142.5 176C94.175 176 55 136.601 55 88c0-48.6 39.175-88 87.5-88S230 39.4 230 88c0 48.601-39.175 88-87.5 88z"
          fill="#FFC07F"
          opacity="1"
        />
        <path
          d="M86.397 102.293l-.446-8.137c-.035-.648.463-1.202 1.113-1.237l.601-.033c.65-.035 1.206.462 1.241 1.11l.446 8.136c.036.648-.463 1.202-1.113 1.237l-.6.033c-.65.036-1.206-.46-1.242-1.109"
          fill="#261D7B"
        />
        <path d="M86.397 102.293l-.446-8.137c-.035-.648.463-1.202 1.113-1.237l.601-.033c.65-.035 1.206.462 1.241 1.11l.446 8.136c.036.648-.463 1.202-1.113 1.237l-.6.033c-.65.036-1.206-.46-1.242-1.109z" />
        <path
          d="M74.659 104.432L72.785 96.5c-.15-.631.244-1.264.877-1.413l.586-.137c.634-.15 1.269.242 1.418.874l1.874 7.932c.149.631-.244 1.264-.877 1.413l-.587.138c-.633.148-1.268-.243-1.417-.875"
          fill="#261D7B"
        />
        <path
          d="M74.659 104.432L72.785 96.5c-.15-.631.244-1.264.877-1.413l.586-.137c.634-.15 1.269.242 1.418.874l1.874 7.932c.149.631-.244 1.264-.877 1.413l-.587.138c-.633.148-1.268-.243-1.417-.875z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M61.28 110.01l-2.878-8.351c-.23-.666.126-1.39.793-1.618l.617-.212c.667-.228 1.394.126 1.623.79l2.878 8.353c.23.665-.126 1.39-.793 1.618l-.617.21c-.667.23-1.394-.125-1.623-.79"
          fill="#261D7B"
        />
        <path
          d="M61.28 110.01l-2.878-8.351c-.23-.666.126-1.39.793-1.618l.617-.212c.667-.228 1.394.126 1.623.79l2.878 8.353c.23.665-.126 1.39-.793 1.618l-.617.21c-.667.23-1.394-.125-1.623-.79z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M86.769 95.456l.009-.05c.31-1.806-1.066-3.458-2.904-3.433l-4.39.061-.75.01c-1.158.016-2.174.724-2.642 1.78-.098.222-.221.432-.368.623-.572.744-.69 1.741-.432 2.642l1.709 5.98c.397 1.391 1.762 2.279 3.2 2.08l4.19-.576c1.649-.227 2.764-1.784 2.461-3.415-.43-2.323-.324-4.274-.083-5.702M65.453 93.024c.23 1.245-.326 2.666-1.473 3.257-1.371.706-2.365 1.967-2.519 3.499-.042.42-.067.866-.065 1.334.008 1.836.438 4.264 2.112 6.669 1.244 1.785 3.658 2.325 5.577 1.294l4.514-2.434c1.031-.556 1.537-1.753 1.215-2.877L73.01 97.45c-.279-.977-1.085-1.713-2.085-1.905l-.017-.003c-1.39-.27-2.085-1.26-2.212-2.338l-1.83 1.614.393-2.013-1.805.22zM88.44 95.172c-.31 1.087-.572 2.506-.583 4.203-.017 2.446 2.056 4.397 4.509 4.33l5.784-.157c3.016-.259 5.293-2.888 5.057-5.947-.21-2.715-2.466-4.831-5.185-5.092l-4.944-.476c-2.083-.2-4.047 1.082-4.624 3.087l-.015.052"
          fill="#E8EAFF"
        />
        <path
          d="M58.307 94.255l-18.713 2.27-10.904 25.57c-.054-.045-.108-.087-.161-.133l-2.142-3.893c-1.655-.785-3.035 1.86-3.863 3.49l-.737 1.451c-2.514 4.561-4.078 7.134-4.474 10.74l-2.039 17.56 1.551 12.186 1.667 13.462c.553 4.46 3.89 8.076 8.302 8.996l15.417 3.217c2.459.513 4.883.126 6.945-.924.167-.083.332-.17.495-.262 3.37-1.901 5.313-5.601 5.22-9.462l-.201-8.334c-.04-1.674.314-3.352 1.145-4.806.088-.154.18-.305.276-.453 3.929-6.067 6.924-12.686 8.311-19.775l.807-4.12c.202-1.03 4.552-16.5 4.41-17.54l-.537-7.48-5.817-4.993c-1.12-2.673.586 1.668-2.234-3.51 0 0-2.94-6.58-1.76-11.86-1.372-.343-.999-.27-.964-1.397z"
          fill="#E8EAFF"
        />
        <path
          d="M56.254 101.182L41 101v19h23c-2.864-3.34-4.718-6.576-5.56-9.707-.844-3.131-1.572-6.168-2.186-9.11z"
          fill="#C1C5E9"
        />
        <path
          d="M65.915 120.08l-2.666-7.736c-.288-.835.158-1.745.996-2.032.837-.286 1.75.158 2.037.993l2.666 7.737c.288.835-.158 1.744-.995 2.031-.838.287-1.75-.157-2.038-.992"
          fill="#D7DAF5"
        />
        <path
          d="M68.77 119.619h14.617c1.31 0 2.372-1.06 2.372-2.366v-4.805c0-1.306-1.062-2.365-2.372-2.365H68.771c-1.31 0-2.372 1.059-2.372 2.365v4.805c0 1.307 1.061 2.366 2.372 2.366"
          fill="#E8EAFF"
        />
        <path
          d="M73.653 88.619l-1.444-7.354c-.124-.637.292-1.254.93-1.379l.41-.08c.64-.124 1.259.291 1.384.928l1.443 7.353c.125.637-.291 1.254-.93 1.379l-.41.08c-.638.125-1.258-.29-1.383-.927"
          fill="#261D7B"
        />
        <path d="M73.653 88.619l-1.444-7.354c-.124-.637.292-1.254.93-1.379l.41-.08c.64-.124 1.259.291 1.384.928l1.443 7.353c.125.637-.291 1.254-.93 1.379l-.41.08c-.638.125-1.258-.29-1.383-.927z" />
        <path
          d="M84.851 118.653l.368-7.722c.028-.583.524-1.033 1.109-1.005l.374.018c.585.027 1.036.522 1.008 1.104l-.368 7.723c-.028.582-.524 1.032-1.109 1.005l-.374-.018c-.585-.028-1.036-.523-1.008-1.105"
          fill="#261D7B"
        />
        <path
          d="M84.851 118.653l.368-7.722c.028-.583.524-1.033 1.109-1.005l.374.018c.585.027 1.036.522 1.008 1.104l-.368 7.723c-.028.582-.524 1.032-1.109 1.005l-.374-.018c-.585-.028-1.036-.523-1.008-1.105z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M99.349 112.53L91.779 110c-1.008-.337-2.109-.35-3.099.032-.447.174-.854.416-1.065.755-.905 1.445-1.69 3.967-.538 7.965.237.983 1.06 1.71 2.067 1.84.218.029.437.066.653.112l6.817 1.46c2.427.536 4.884-.77 5.79-3.077 1.03-2.615-.289-5.565-2.928-6.55"
          fill="#E8EAFF"
        />
        <path
          d="M44.495 193.696l-19.792-3.85c-1.103-.214-1.823-1.28-1.608-2.38l.138-.706c.215-1.1 1.285-1.817 2.388-1.603l19.792 3.85c1.104.215 1.824 1.28 1.608 2.38l-.138.706c-.215 1.1-1.284 1.818-2.388 1.603M44.172 106.73l-6.33-7.576c-.612-.732-.512-1.82.222-2.43l.47-.39c.735-.61 1.825-.51 2.437.222l6.33 7.576c.611.732.512 1.82-.222 2.429l-.47.39c-.735.61-1.825.511-2.437-.22"
          fill="#342D71"
        />
        <path
          d="M36.112 265.871l-20.5-4.077c-10.768-2.142-17.606-15.685-14.575-28.868l6.22-27.06c2.538-11.044 11.209-17.982 20.229-16.189l14.825 2.95c8.472 1.685 14.772 10.581 15.26 21.193.074 1.625.14 3.25.28 4.868l3.322 38.722c-.267 13.788-14.24 10.613-25.061 8.461"
          fill="#ECEFFC"
        />
        <path
          d="M73.042 82.567c-.005-.016-.008-.033-.012-.05-.391-1.69-2.24-2.59-3.797-1.814l-9.725 4.853-1.175 8.952 6.137-.745-.37 1.888 2.71-2.389 7.123-.096 1.31-.879c1.277-.856 1.544-2.598.621-3.826-1.67-2.224-2.455-4.336-2.822-5.894M89.57 79.89c-1.047-2.702-4.034-4.129-6.864-3.474l-5.333 1.236c-1.351.312-2.373 1.437-2.51 2.813-.04.394-.065.827-.068 1.294-.007 1.514.239 3.605 1.218 5.995.67 1.633 2.517 2.449 4.176 1.834l6.019-2.235c2.988-1.11 4.53-4.445 3.361-7.463M26.079 113.355l6.978-11.536c.176-.292 1.51-2.442 2.62-4.177.534-.834 1.793-.716 2.153.205.183.468.414.977.706 1.518 1.78 3.295 4.742 5.732 7.49 7.424l.024.015c1.264.777 1.673 2.42.92 3.697l-1.127 1.914-8.043 13.69c-4.664-.52-8.184-2.055-10.598-3.584-2.489-1.578-3.325-4.815-1.996-7.44l.873-1.726z"
          fill="#E8EAFF"
        />
        <path
          d="M49.93 124.912l-2.48-7.2c-.428-1.238.234-2.588 1.477-3.014 1.243-.425 2.596.234 3.024 1.473l2.48 7.2c.427 1.238-.234 2.588-1.477 3.014-1.244.426-2.597-.234-3.024-1.473M31.835 132.073l-2.985-7.095c-.5-1.188.061-2.554 1.253-3.052 1.191-.498 2.562.061 3.061 1.248l2.985 7.095c.5 1.187-.061 2.554-1.252 3.052-1.192.498-2.562-.06-3.062-1.248"
          fill="#342D71"
        />
        <path
          d="M24.876 121.338c-4.552 2.373-7.664 6.803-8.347 11.878l-2.612 19.407c.534 4.981 5.184 8.53 10.22 7.644 3.659-.646 6.45-3.641 7.1-7.29l2.11-11.843.115-.65c.573-3.212.207-6.52-1.055-9.532l-1.257-2.997-2.06-5.074c-.671-1.654-2.628-2.37-4.214-1.543"
          fill="#9FB5E3"
        />
        <path
          d="M32.015 153.623c-1.078 4.979-6 8.144-10.995 7.07-4.994-1.075-8.17-5.982-7.092-10.96 1.078-4.98 6-8.145 10.995-7.071 4.994 1.074 8.17 5.981 7.092 10.96z"
          stroke="#342D71"
          strokeWidth="1.333"
        />
        <path
          d="M55.344 87.583c-.183-.934.428-1.84 1.366-2.023l.6-.118c.938-.183 1.847.427 2.03 1.361l1.458 7.421c.183.935-.428 1.84-1.366 2.024l-.6.117c-.938.183-1.847-.426-2.03-1.361l-1.458-7.42z"
          fill="#261D7B"
        />
        <path
          d="M56.801 95.004l-1.457-7.42c-.183-.935.428-1.842 1.366-2.024l.6-.118c.938-.183 1.847.427 2.03 1.361l1.458 7.421c.183.935-.428 1.84-1.366 2.024l-.6.117c-.938.183-1.847-.426-2.03-1.361z"
          stroke="#2E2E54"
          strokeWidth="0.5"
        />
        <path
          d="M80.862 88.771c.432-3.664-2.386-6.908-6.085-7.004l-9.436-.247c-.168-.005-1.064 0-2.095.008-2.386.018-4.393 1.772-4.741 4.125-.278 1.876-.288 4.216.486 6.737.808 2.628 3.508 4.202 6.221 3.71h.01l10.505-1.886c2.726-.49 4.811-2.7 5.135-5.443M56.165 87.678c.092-2.118-2.284-3.436-4.066-2.28-2.346 1.522-4.607 3.004-4.772 3.113l-6.51 4.318-.01.006c-.55.365-.811 1.033-.658 1.675.496 2.074 2.154 6.111 7.695 9.81 1.74 1.162 4.085.824 5.413-.789l.034-.04 4.061-4.897.014-.017c.804-.966.936-2.31.378-3.435-1.333-2.69-1.673-5.28-1.579-7.464"
          fill="#E8EAFF"
        />
        <path
          d="M46.094 130.424l-3.998 1.369c-3.111 1.065-6.5-.585-7.568-3.686l-1.373-3.986c-1.07-3.102.586-6.48 3.697-7.544l3.999-1.37c3.11-1.065 6.499.585 7.567 3.686l1.374 3.986c1.068 3.102-.587 6.48-3.698 7.545"
          fill="#9FB5E3"
        />
        <path
          d="M189.135 193.846c-25.013 17.945-68.92 17.824-93.691-.258-2.22-1.62-3.444-3.702-3.444-5.857V162h101v25.688c0 2.18-1.254 4.286-3.523 5.913l-.342.245z"
          fill="#342D71"
        />
        <ellipse fill="#342D71" cx="142.5" cy="162.46" rx="50.5" ry="23.46" />
        <path
          d="M197.603 105.293l.446-8.137c.035-.648-.463-1.202-1.113-1.237l-.601-.033c-.65-.035-1.206.462-1.241 1.11l-.446 8.136c-.036.648.463 1.202 1.113 1.237l.6.033c.65.036 1.206-.46 1.242-1.109"
          fill="#261D7B"
        />
        <path d="M197.603 105.293l.446-8.137c.035-.648-.463-1.202-1.113-1.237l-.601-.033c-.65-.035-1.206.462-1.241 1.11l-.446 8.136c-.036.648.463 1.202 1.113 1.237l.6.033c.65.036 1.206-.46 1.242-1.109z" />
        <path
          d="M209.341 107.432l1.874-7.932c.15-.631-.244-1.264-.877-1.413l-.586-.137c-.634-.15-1.269.242-1.418.874l-1.874 7.932c-.149.631.244 1.264.877 1.413l.587.138c.633.148 1.268-.243 1.417-.875"
          fill="#261D7B"
        />
        <path
          d="M209.341 107.432l1.874-7.932c.15-.631-.244-1.264-.877-1.413l-.586-.137c-.634-.15-1.269.242-1.418.874l-1.874 7.932c-.149.631.244 1.264.877 1.413l.587.138c.633.148 1.268-.243 1.417-.875z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M222.72 113.01l2.878-8.351c.23-.666-.126-1.39-.793-1.618l-.617-.212c-.667-.228-1.394.126-1.623.79l-2.878 8.353c-.23.665.126 1.39.793 1.618l.617.21c.667.23 1.394-.125 1.623-.79"
          fill="#261D7B"
        />
        <path
          d="M222.72 113.01l2.878-8.351c.23-.666-.126-1.39-.793-1.618l-.617-.212c-.667-.228-1.394.126-1.623.79l-2.878 8.353c-.23.665.126 1.39.793 1.618l.617.21c.667.23 1.394-.125 1.623-.79z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M197.231 98.456l-.009-.05c-.31-1.806 1.066-3.458 2.904-3.433l4.39.061.75.01c1.158.016 2.174.724 2.642 1.78.098.222.221.432.368.623.572.744.69 1.741.432 2.642l-1.709 5.98c-.397 1.391-1.762 2.279-3.2 2.08l-4.19-.576c-1.649-.227-2.764-1.784-2.461-3.415.43-2.323.324-4.274.083-5.702M218.547 96.024c-.23 1.245.326 2.666 1.473 3.257 1.371.706 2.365 1.967 2.519 3.499.042.42.067.866.065 1.334-.008 1.836-.438 4.264-2.112 6.669-1.244 1.785-3.658 2.325-5.577 1.294l-4.514-2.434c-1.031-.556-1.537-1.753-1.215-2.877l1.805-6.316c.279-.977 1.085-1.713 2.085-1.905l.017-.003c1.39-.27 2.085-1.26 2.212-2.338l1.83 1.614-.393-2.013 1.805.22zM195.56 98.172c.31 1.087.572 2.506.583 4.203.017 2.446-2.056 4.397-4.509 4.33l-5.784-.157c-3.016-.259-5.293-2.888-5.057-5.947.21-2.715 2.466-4.831 5.185-5.092l4.944-.476c2.083-.2 4.047 1.082 4.624 3.087l.015.052"
          fill="#E8EAFF"
        />
        <path
          d="M225.693 97.255l18.713 2.27 10.904 25.57c.054-.045.108-.087.161-.133l2.142-3.893c1.655-.785 3.035 1.86 3.863 3.49l.737 1.451c2.514 4.561 4.078 7.134 4.474 10.74l2.039 17.56-1.551 12.186-1.667 13.462c-.553 4.46-3.89 8.076-8.302 8.996l-15.417 3.217c-2.459.513-4.883.126-6.945-.924-.167-.083-.332-.17-.495-.262-3.37-1.901-5.313-5.601-5.22-9.462l.201-8.334c.04-1.674-.314-3.352-1.145-4.806-.088-.154-.18-.305-.276-.453-3.929-6.067-6.924-12.686-8.311-19.775l-.807-4.12c-.202-1.03-4.552-16.5-4.41-17.54l.537-7.48 5.817-4.993c1.12-2.673-.586 1.668 2.234-3.51 0 0 2.94-6.58 1.76-11.86 1.372-.343.999-.27.964-1.397z"
          fill="#E8EAFF"
        />
        <path
          d="M228.746 103.182L244 103v19h-23c2.864-3.34 4.718-6.576 5.56-9.707.844-3.131 1.572-6.168 2.186-9.11z"
          fill="#C1C5E9"
        />
        <path
          d="M218.085 123.08l2.666-7.736c.288-.835-.158-1.745-.996-2.032-.837-.286-1.75.158-2.037.993l-2.666 7.737c-.288.835.158 1.744.995 2.031.838.287 1.75-.157 2.038-.992"
          fill="#D7DAF5"
        />
        <path
          d="M215.23 122.619h-14.617c-1.31 0-2.372-1.06-2.372-2.366v-4.805c0-1.306 1.062-2.365 2.372-2.365h14.616c1.31 0 2.372 1.059 2.372 2.365v4.805c0 1.307-1.061 2.366-2.372 2.366"
          fill="#E8EAFF"
        />
        <path
          d="M210.347 91.619l1.444-7.354c.124-.637-.292-1.254-.93-1.379l-.41-.08c-.64-.124-1.259.291-1.384.928l-1.443 7.353c-.125.637.291 1.254.93 1.379l.41.08c.638.125 1.258-.29 1.383-.927"
          fill="#261D7B"
        />
        <path d="M210.347 91.619l1.444-7.354c.124-.637-.292-1.254-.93-1.379l-.41-.08c-.64-.124-1.259.291-1.384.928l-1.443 7.353c-.125.637.291 1.254.93 1.379l.41.08c.638.125 1.258-.29 1.383-.927z" />
        <path
          d="M199.149 121.653l-.368-7.722c-.028-.583-.524-1.033-1.109-1.005l-.374.018c-.585.027-1.036.522-1.008 1.104l.368 7.723c.028.582.524 1.032 1.109 1.005l.374-.018c.585-.028 1.036-.523 1.008-1.105"
          fill="#261D7B"
        />
        <path
          d="M199.149 121.653l-.368-7.722c-.028-.583-.524-1.033-1.109-1.005l-.374.018c-.585.027-1.036.522-1.008 1.104l.368 7.723c.028.582.524 1.032 1.109 1.005l.374-.018c.585-.028 1.036-.523 1.008-1.105z"
          stroke="#261D7B"
          strokeWidth="0.5"
        />
        <path
          d="M184.651 115.53l7.57-2.529c1.008-.337 2.109-.35 3.099.032.447.174.854.416 1.065.755.905 1.445 1.69 3.967.538 7.965-.237.983-1.06 1.71-2.067 1.84-.218.029-.437.066-.653.112l-6.817 1.46c-2.427.536-4.884-.77-5.79-3.077-1.03-2.615.289-5.565 2.928-6.55"
          fill="#E8EAFF"
        />
        <path
          d="M239.505 196.696l19.792-3.85c1.103-.214 1.823-1.28 1.608-2.38l-.138-.706c-.215-1.1-1.285-1.817-2.388-1.603l-19.792 3.85c-1.104.215-1.824 1.28-1.608 2.38l.138.706c.215 1.1 1.284 1.818 2.388 1.603M239.828 109.73l6.33-7.576c.612-.732.512-1.82-.222-2.43l-.47-.39c-.735-.61-1.825-.51-2.437.222l-6.33 7.576c-.611.732-.512 1.82.222 2.429l.47.39c.735.61 1.825.511 2.437-.22"
          fill="#342D71"
        />
        <path
          d="M268.388 264.794c10.768-2.142 17.606-15.685 14.575-28.868l-6.22-27.06c-2.538-11.044-11.209-17.982-20.229-16.189l-14.825 2.95c-8.472 1.685-14.772 10.581-15.26 21.193-.074 1.625-.14 3.25-.28 4.868l-3.322 38.722c.267 13.788 14.24 10.613 25.061 8.461l20.5-4.077z"
          fill="#ECEFFC"
        />
        <path
          d="M210.958 85.567c.005-.016.008-.033.012-.05.391-1.69 2.24-2.59 3.797-1.814l9.725 4.853 1.175 8.952-6.137-.745.37 1.888-2.71-2.389-7.123-.096-1.31-.879c-1.277-.856-1.544-2.598-.621-3.826 1.67-2.224 2.455-4.336 2.822-5.894M194.43 82.89c1.047-2.702 4.034-4.129 6.864-3.474l5.333 1.236c1.351.312 2.373 1.437 2.51 2.813.04.394.065.827.068 1.294.007 1.514-.239 3.605-1.218 5.995-.67 1.633-2.517 2.449-4.176 1.834l-6.019-2.235c-2.988-1.11-4.53-4.445-3.361-7.463M257.921 116.355l-6.978-11.536c-.176-.292-1.51-2.442-2.62-4.177-.534-.834-1.793-.716-2.153.205-.183.468-.414.977-.706 1.518-1.78 3.295-4.742 5.732-7.49 7.424l-.024.015c-1.264.777-1.673 2.42-.92 3.697l1.127 1.914 8.043 13.69c4.664-.52 8.184-2.055 10.598-3.584 2.489-1.578 3.325-4.815 1.996-7.44l-.873-1.726z"
          fill="#E8EAFF"
        />
        <path
          d="M234.07 127.912l2.48-7.2c.428-1.238-.234-2.588-1.477-3.014-1.243-.425-2.596.234-3.024 1.473l-2.48 7.2c-.427 1.238.234 2.588 1.477 3.014 1.244.426 2.597-.234 3.024-1.473M252.165 135.073l2.985-7.095c.5-1.188-.061-2.554-1.253-3.052-1.191-.498-2.562.061-3.061 1.248l-2.985 7.095c-.5 1.187.061 2.554 1.252 3.052 1.192.498 2.562-.06 3.062-1.248"
          fill="#342D71"
        />
        <path
          d="M267.471 136.216l2.612 19.407c-.534 4.981-5.184 8.53-10.22 7.644-3.659-.646-6.45-3.641-7.1-7.29l-2.11-11.843-.115-.65c-.573-3.212-.207-6.52 1.055-9.532l1.257-2.997 2.06-5.074c.671-1.654 2.628-2.37 4.214-1.543 4.552 2.373 7.664 6.803 8.347 11.878z"
          fill="#9FB5E3"
        />
        <path
          d="M251.985 156.623c1.078 4.979 6 8.144 10.995 7.07 4.994-1.075 8.17-5.982 7.092-10.96-.638-2.948-2.622-5.26-5.172-6.433-1.757-.809-3.784-1.077-5.823-.638-4.994 1.074-8.17 5.981-7.092 10.96z"
          stroke="#261D7B"
          strokeWidth="1.333"
        />
        <path
          d="M228.656 90.583c.183-.934-.428-1.84-1.366-2.023l-.6-.118c-.938-.183-1.847.427-2.03 1.361l-1.458 7.421c-.183.935.428 1.84 1.366 2.024l.6.117c.938.183 1.847-.426 2.03-1.361l1.458-7.42z"
          fill="#261D7B"
        />
        <path
          d="M227.199 98.004l1.457-7.42c.183-.935-.428-1.842-1.366-2.024l-.6-.118c-.938-.183-1.847.427-2.03 1.361l-1.458 7.421c-.183.935.428 1.84 1.366 2.024l.6.117c.938.183 1.847-.426 2.03-1.361z"
          stroke="#2E2E54"
          strokeWidth="0.5"
        />
        <path
          d="M203.138 91.771c-.432-3.664 2.386-6.908 6.085-7.004l9.436-.247c.168-.005 1.064 0 2.095.008 2.386.018 4.393 1.772 4.741 4.125.278 1.876.288 4.216-.486 6.737-.808 2.628-3.508 4.202-6.221 3.71h-.01l-10.505-1.886c-2.726-.49-4.811-2.7-5.135-5.443M227.835 90.678c-.092-2.118 2.284-3.436 4.066-2.28 2.346 1.522 4.607 3.004 4.772 3.113l6.51 4.318.01.006c.55.365.811 1.033.658 1.675-.496 2.074-2.154 6.111-7.695 9.81-1.74 1.162-4.085.824-5.413-.789l-.034-.04-4.061-4.897-.014-.017c-.804-.966-.936-2.31-.378-3.435 1.333-2.69 1.673-5.28 1.579-7.464"
          fill="#E8EAFF"
        />
        <path
          d="M237.906 133.424l3.998 1.369c3.111 1.065 6.5-.585 7.568-3.686l1.373-3.986c1.07-3.102-.586-6.48-3.697-7.544l-3.999-1.37c-3.11-1.065-6.499.585-7.567 3.686l-1.374 3.986c-1.068 3.102.587 6.48 3.698 7.545"
          fill="#9FB5E3"
        />

        <path
          d="M142.5 176C94.175 176 55 136.601 55 88c0-48.6 39.175-88 87.5-88S230 39.4 230 88c0 48.601-39.175 88-87.5 88z"
          fill="#5F93FF"
          opacity="0.85"
          className="glow"
        />

        <path
          d="M142.5 176C94.175 176 55 136.601 55 88c0-48.6 39.175-88 87.5-88S230 39.4 230 88c0 48.601-39.175 88-87.5 88z"
          fill="url(#a)"
          opacity="0.55"
        />
        <path
          d="M56.987 109.356l-3.62 1.27-.35.128c-2.06.745-3.29 2.85-2.966 5.025.353 2.37 1.14 5.457 2.882 8.149 1.22 1.883 3.63 2.587 5.675 1.676l3.69-1.7c3.678-1.694 5.63-5.927 4.266-9.754-1.41-3.951-5.647-6.031-9.577-4.794zM227.013 112.356l3.62 1.27.35.128c2.06.745 3.29 2.85 2.966 5.025-.353 2.37-1.14 5.457-2.882 8.149-1.22 1.883-3.63 2.587-5.675 1.676l-3.69-1.7c-3.678-1.694-5.63-5.927-4.266-9.754 1.41-3.951 5.647-6.031 9.577-4.794z"
          fill="#9FB5E3"
        />
      </g>
    </svg>
  );
};
