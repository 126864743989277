import React from "react";
import styled from "styled-components";
import Box from "../Box";

const SvgWrapper = styled(Box)`
  margin-right: 8px;
  transform: rotate(-90deg);
`;

const EditIcon = props => {
  return (
    <SvgWrapper mb={props.small ? [0, "4px"] : ["2px", "8px"]}>
      {props.small && (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
          <g fill="none" fillRule="evenodd" transform="translate(2 2)">
            <path
              fill="#FCFBF8"
              fillRule="nonzero"
              d="M8.13675203 13.18951953c-.20306645.20306645-.2135833.52178531-.0147293.72063932l1.75755875 1.75755874c.19493168.19493169.51629642.18961359.7206393-.0147293l5.14245654-5.14245653c.20306642-.20306642.21358326-.5217853.01472924-.72063932L13.99984783 8.0323337c-.19493167-.19493166-.51629641-.1896136-.7206393.01472928l-5.1424565 5.14245655zm6.76398625-6.7639863L13.64689282 7.6793787l2.46346878 2.46346878L17.36420708 8.889002c.42553313-.42553313.07270413-.77836212.07270413-.77836212l-1.75781083-1.7578108s-.35282895-.35282899-.7783621.07270416zM6.9293784 14.39689312l-.4444429 2.9143143 2.90791168-.45084552-2.46346878-2.46346878z"
            />
            <circle
              cx="12"
              cy="12"
              r="12"
              fill="#FFF"
              stroke="#FFF"
              strokeWidth="4"
              opacity=".35"
            />
          </g>
        </svg>
      )}

      {!props.small && (
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36">
          <g fill="none" fillRule="evenodd" transform="translate(2 2)">
            <path
              fill="#FCFBF8"
              fillRule="nonzero"
              d="M10.84900302 17.58602593c-.27075522.27075521-.28477768.69571369-.019639.96085236L13.1727757 20.89029c.2599089.2599089.68839518.2528181.96085233-.01963905l6.85660872-6.85660872c.27075524-.27075523.28477767-.69571376.019639-.96085243l-2.34341165-2.34341166c-.2599089-.2599089-.68839522-.25281815-.9608524.01963904l-6.85660869 6.85660876zm9.01864835-9.01864842l-1.67179395 1.67179394 3.28462506 3.28462505 1.67179397-1.67179396c.5673775-.5673775.09693886-1.03781614.09693886-1.03781614l-2.3437478-2.34374775s-.47043862-.47043867-1.03781614.09693886zM9.2391715 19.19585736l-.59259047 3.88575238 3.87721553-.60112732-3.28462506-3.28462506z"
            />
            <circle
              cx="16"
              cy="16"
              r="16"
              fill="#FFF"
              stroke="#FFF"
              strokeWidth="4"
              opacity=".35"
            />
          </g>
        </svg>
      )}
    </SvgWrapper>
  );
};

export default EditIcon;
