import { createSelector } from "reselect";
import _ from "lodash";

export const getAnswers = state => state.answers;

export const getAnswer = createSelector(
  getAnswers,
  (_, quizId) => quizId,
  (answers, quizId) => _.get(answers, quizId)
);

export const getConfirmedAnswers = createSelector(getAnswers, answers =>
  Object.keys(_.pickBy(answers, answer => answer.confirmed))
);

export const getChangedAnswers = createSelector(getAnswers, answers =>
  Object.keys(_.pickBy(answers, answer => answer.changed))
);
