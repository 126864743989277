import React from "react";
import styled from "styled-components";
import Box from "../Box";

const SvgWrapper = styled(Box)`
  margin-right: 8px;
  transform: rotate(-90deg);
`;

const PercentageIcon = props => {
  const size = props.small ? 12 : 16;
  let dashOffset;
  if (props.small) {
    dashOffset = 1000 - (props.completed / 100) * 75;
  } else {
    dashOffset = 1000 - (props.completed / 100) * 101;
  }
  return (
    <SvgWrapper mb={props.small ? ["2px", "4px"] : ["2px", "8px"]}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size * 2 + 4}
        height={size * 2 + 4}
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#FFF"
          strokeWidth="4"
          transform="translate(2 2)"
        >
          <circle cx={size} cy={size} r={size} opacity=".35" />
          <circle
            cx={size}
            cy={size}
            r={size}
            opacity="1"
            strokeDashoffset={dashOffset}
            strokeDasharray="1000"
          />
        </g>
      </svg>
    </SvgWrapper>
  );
};

export default PercentageIcon;
