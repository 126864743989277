import { createSelector } from "reselect";
import _ from "lodash";

import { ESSAY } from "../../constants/quiztypes";
import { getQuiz } from "../quiz/selectors";
import { getAnswer } from "../answer/selectors";
import { getConfirmedAnswers, getChangedAnswers } from "../answer/selectors";

import {
  getEssaysAwaitingConfirmation,
  getEssaysAwaitingPeerReviewsGiven,
  getRejectedIds
} from "../stats/selectors";

import { config } from "../course-config";

export const getTags = state => _.get(state, "tags", {});

export const quizzesPerSection = createSelector(
  getTags,
  tags => (part, section) =>
    _.get(tags, `['${config.COURSE_SHORT_ID}_${part}_${section}']`, [])
); // this is wrong

export const confirmedAnswersPerSection = state => (part, section) =>
  quizzesPerSection(state)(part, section).filter(quizId =>
    _.get(getAnswer(state, quizId), "confirmed", false)
  );

export const essaysPerSection = (state, part, section) =>
  quizzesPerSection(state)(part, section).filter(
    (
      quizId // that's wrong as well
    ) => _.get(getQuiz(state, quizId), "data.type") === ESSAY
  );

export const unconfirmedEssaysPerSection = state => (part, section) =>
  essaysPerSection(state)(part, section).filter(
    quiz => !_.get(getAnswer(state, _.get(quiz, "_id")), "confirmed", true)
  );

export const hasPeerReviewsAwaiting = (state, part, section) =>
  essaysPerSection(state, part, section)
    .map(
      quizId =>
        _.get(getAnswer(state, quizId), "confirmed", false) &&
        _.includes(getEssaysAwaitingPeerReviewsGiven(state), quizId)
    )
    .some(v => !!v);

const tagsSelector = getFunction =>
  createSelector(getTags, getFunction, (tags, data) =>
    _.reduce(
      Object.keys(tags),
      (obj, tag) => {
        obj[tag] = _.intersection(tags[tag], data);

        return obj;
      },
      {}
    )
  );

export const getTagsWithAwaitingConfirmation = tagsSelector(
  getEssaysAwaitingConfirmation
);

export const getTagsWithAwaitingPeerReviewsGiven = tagsSelector(
  getEssaysAwaitingPeerReviewsGiven
);

export const getTagsWithRejected = tagsSelector(getRejectedIds);

export const getTagsWithChanged = tagsSelector(getChangedAnswers);

export const getTagsWithConfirmed = tagsSelector(getConfirmedAnswers);

const sectionSelector = getFunction =>
  createSelector(getTags, getFunction, (tags, essays) =>
    Object.keys(
      _.pickBy(tags, tagsByTag =>
        tagsByTag.map(quizId => _.includes(essays, quizId)).some(v => !!v)
      )
    )
  );
/*   {
    return _.reduce(Object.keys(tags), (obj, tag) => {
      if (tags[tag].map(quizId => _.includes(essays, quizId)).some(v => !!v)) {
        obj.push(tag)
      }
      return obj
    }, [])
  }
) */

export const getSectionsWithAwaitingPeerReviews = sectionSelector(
  getEssaysAwaitingPeerReviewsGiven
);

export const getSectionsWithRejected = sectionSelector(getRejectedIds);

export const getSectionsWithAwaitingConfirmation = sectionSelector(
  getEssaysAwaitingConfirmation
);

export const getAwaitingPeerReviewFeedback = (state, tag) =>
  _.includes(getSectionsWithAwaitingConfirmation(state), tag);

export const getAwaitingPeerReview = (state, tag) =>
  _.includes(getSectionsWithAwaitingPeerReviews(state), tag);

export const getRejectedPeerReview = (state, tag) =>
  _.includes(getSectionsWithRejected(state), tag);
