import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FormattedMessage } from "react-intl";

import TextF8 from "../TextF8";
import TextF4 from "../TextF4";
import { themeGet } from "styled-system";
import Box from "../Box";
import StyledLink from "../StyledLink";
import SectionCompletion from "../SectionCompletion";
import Romanize from "../Romanize";
import styled, { keyframes } from "styled-components";

import withAuthentication from "../Session/withAuthentication";
import {
  getTagsWithAwaitingConfirmation,
  getTagsWithAwaitingPeerReviewsGiven,
  getTagsWithRejected,
  getTagsWithChanged
} from "../../state/tags/selectors";

import {
  Part1Svg,
  Part2Svg,
  Part3Svg,
  Part4Svg,
  Part5Svg,
  Part6Svg
} from "./PartSvgs";


import { injectCourseProgress, RequiredAction } from "moocfi-quizzes";

import { currentLocale } from "../../services/locale";

//Certain greek letters are showing outside of the unicode-range when strong
//Therefore, the wrong font is loading
//No great way to pass the language key into this component (?)
//Short-term solution, get lang based on URL for Greek.

const Card = styled(Box)`
  height: 100%;
  hover {
    cursor: pointer;
  }
`;

const glow = keyframes`
  0% { opacity: 0.85; fill: #5F93FF;}
  25% { opacity: 0.70; fill: #5adee4;}
  50% { opacity: 0.85; fill: #5fd4ff;}
  75% { opacity: 0.85; fill: #ff9bb7;}
`;

const animateLines = keyframes`
  0% { transform: translateX(500px);}
  100% { transform: translateX(-500px);}
`;

const animateCar = keyframes`
  0% { transform: translate(112px, 107px);}
  50% { transform: translate(112px, 106px);}
  100% { transform: translate(112px, 107px);}
`;

const animateShaddow = keyframes`
  0% { transform: scale(1);}
  50% { transform: scale(1.01) translateX(-3px);}
`;
const PartLink = styled(StyledLink)`
  display: block;
  h3 {
    transition: all 0.4s ease;
  }
  .illustration-box {
    height: 0;
    padding-bottom: 70%;
    overflow: hidden;
    position: relative;
    .inner {
      height: 79.41%;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: ${props => (props.partcolor ? props.partcolor : "red")};
    }
  }
  :hover {
    h3 {
      color: ${props => (props.partcolor ? props.partcolor : "red)")};
    }
  }
  &.part-1 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: -20px;
      display: block;
      transition: all 0.4s ease;
    }
    :hover {
      .inner {
        svg {
          bottom: -15px;
          transform: scale(1.02) rotate(-1deg);
        }
      }
    }
  }
  &.part-6 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      display: block;
      .glow {
      }
    }
    :hover {
      svg {
        .glow {
          animation: ${glow} 6s infinite;
        }
      }
    }
  }
  &.part-3 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      display: block;
      .car {
        transform: translate(112px, 107px);
      }
      .lines {
        transform: translateX(500px);
      }
      .sun {
        transition: transform 0.5s ease;
        transform: translate(247px, 4px);
      }
    }
    :hover {
      svg {
        .bubble-1,
        .bubble-2 {
          opacity: 1;
        }
        .car {
          animation: ${animateCar} 1s infinite;
        }
        .sun {
          transform: translate(207px, 4px);
          transition: transform 20s ease;
        }
        .shaddow {
          animation: ${animateShaddow} 1s infinite;
        }
        .lines {
          animation: ${animateLines} 1s infinite;
        }
      }
    }
  }
  &.part-4 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      display: block;
      .bubble-1 {
        transition: opacity 0.3s ease;
      }
      .bubble-2 {
        transition: opacity 0.3s ease 0.1s;
      }
    }
    :hover {
      svg {
        .bubble-1,
        .bubble-2 {
          opacity: 1;
        }
        .bubble-1 {
          transition: opacity 0.3s ease 0.1s;
        }
        .bubble-2 {
          transition: opacity 0.3s ease 0s;
        }
      }
    }
  }
  &.part-5 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      display: block;
      .circle {
        fill: #1274bf;
      }
      .circle-1 {
        transition: fill 0.3s ease 0s;
      }
      .circle-2 {
        transition: fill 0.3s ease 0.2s;
      }
      .circle-3 {
        transition: fill 0.3s ease 0.4s;
      }
      .circle-4 {
        transition: fill 0.3s ease 0.6s;
      }
      .circle-5 {
        transition: fill 0.3s ease 0.8s;
      }
    }
    :hover {
      svg {
        .circle {
          fill: #2ce6fb;
        }
      }
    }
  }
  &.part-2 {
    svg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      display: block;
      .rotate-me {
        transition: transform 0.5s ease;
        transform-style: preserve-3d;
        transform-origin: 150px 100px;
        display: block;
      }
      .take-me-up,
      .take-me-down {
        transition: transform 1s ease;
        position: relative;
      }
      .take-me-down {
        transform: translateY(20px);
      }
      .take-me-up {
        transform: translateY(20px);
      }
    }
    :hover {
      .inner {
        svg {
          .rotate-me {
            transform: rotate(-20deg);
          }
          .take-me-up {
            transform: rotate(5deg) translateY(-26px);
          }
          .take-me-down {
            transform: translateY(40px);
          }
        }
      }
    }
  }
`;

const CardSubHeading = styled(TextF8)`
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionLink = styled(StyledLink)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  display: block;
  color: #29264c;
  padding: 0 2rem;
  margin-top: -1px;
  :hover {
    background: #e9e9ed;
  }
`;

const SectionHeaderWrap = styled(Box)`
  border-bottom: 1px solid #e9e9ed;
`;

const SectionLinkInner = styled(Box)`
  border-bottom: 1px solid #e9e9ed;
  padding: 1rem 0 0.7rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
`;

const CardHeading = styled(TextF4)`
  margin: 0;
`;

const SectionMeta = styled(TextF8)`
  color: #9492a5;
  margin: 0;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  width: 70px;
  text-align: right;
`;

const PendingReviewGlow = keyframes`
  0% { opacity: 1;}
  50% { opacity: 0.6;}
  75% { opacity: 1;}
`;

const PendingPeerReview = styled.p`
  color: #9492a5;
  display: block;
  position: relative;
  font-size: 0.875rem;
  padding-left: 16px;
  margin: 0.5rem 0;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  :before {
    content: "";
    display: block;
    background: rgb(255, 192, 127);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    animation: ${PendingReviewGlow} 1.5s infinite;
  }
`;

const AwaitingPeerReview = styled.p`
  color: #9492a5;
  display: block;
  position: relative;
  font-size: 0.875rem;
  padding-left: 16px;
  margin: 0.5rem 0;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  :before {
    content: "";
    display: block;
    background: rgb(255, 192, 127);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    animation: ${PendingReviewGlow} 1.5s infinite;
  }
`;

const RejectedPeerReview = styled.p`
  color: #9492a5;
  display: block;
  position: relative;
  font-size: 0.875rem;
  padding-left: 16px;
  margin: 0.5rem 0;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  :before {
    content: "";
    display: block;
    background: #ff556e;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    animation: ${PendingReviewGlow} 1.5s infinite;
  }
`;

const Unsubmitted = styled.p`
  color: #9492a5;
  display: block;
  position: relative;
  font-size: 0.875rem;
  padding-left: 16px;
  margin: 0.5rem 0;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  :before {
    content: "";
    display: block;
    background: rgb(255, 192, 127);
    height: 9px;
    width: 9px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 4px;
    animation: ${PendingReviewGlow} 1.5s infinite;
  }
`;

const PartLinkBox = props => {
  let svgs = {
    1: Part1Svg,
    2: Part2Svg,
    3: Part3Svg,
    4: Part4Svg,
    5: Part5Svg,
    6: Part6Svg
  };

  let partColor = "#F5766F";

  switch (props.part) {
    case 1:
      partColor = "#FF556E";
      break;
    case 2:
      partColor = "#8A8ED8";
      break;
    case 3:
      partColor = "#50B9E8";
      break;
    case 4:
      partColor = "#E15FBD";
      break;
    case 5:
      partColor = "#00B5AA";
      break;
    case 6:
      partColor = "#FFC07F";
      break;
    default:
      break;
  }

  let SvgTag = svgs[props.part];

  return (
    <PartLink
      textDecoration="none"
      className={"part-" + props.part}
      to={props.partPath}
      partcolor={partColor}
    >
      <Box className="illustration-box">
        <Box className="inner">
          <SvgTag />
        </Box>
      </Box>
      <Box px={4} pt={4} bg="white">
        <CardSubHeading>
          {currentLocale() === "hu" ? (
            <React.Fragment>
              {props.part}. <FormattedMessage id="globals.chapter" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormattedMessage id="globals.chapter" /> {props.part}
            </React.Fragment>
          )}
        </CardSubHeading>
        <CardHeading>{props.partTitle}</CardHeading>
      </Box>
    </PartLink>
  );
};

class PartCard extends React.Component {
  static contextTypes = {
    loggedIn: PropTypes.bool
  };

  render() {
    const {
      frontmatter,
      data,
      hideSections,
      tagsWithChanged,
      courseProgressData
    } = this.props;

    const sections = {};

    if (courseProgressData) {
      const exercise_completions_by_section =
        courseProgressData.exercise_completions_by_section;
      if (exercise_completions_by_section) {
        for (const completion of exercise_completions_by_section) {
          sections[completion.part] = sections[completion.part] || {};
          sections[completion.part][completion.section] =
            completion.required_actions;
        }
      }
    }

    const hasLeft = (action, partNumber, sectionNumber) =>
      sections[partNumber] &&
      sections[partNumber][sectionNumber] &&
      sections[partNumber][sectionNumber].includes(action);

    return (
      <Card>
        <PartLinkBox
          part={frontmatter.part}
          partTitle={frontmatter.title}
          partPath={frontmatter.path}
        />

        <Box pb={4} bg="#fff">
          {hideSections ? (
            ""
          ) : (
            <div>
              <SectionHeaderWrap
                mx={4}
                pt={"1.5rem"}
                pb={"0.5rem"}
                display="flex"
                justifyContent="space-between"
              >
                <TextF8 color="#9492A5" m="0">
                  <FormattedMessage id="globals.section" />
                </TextF8>
                <TextF8 color="#9492A5" m="0">
                  <FormattedMessage id="globals.exercises" />
                </TextF8>
              </SectionHeaderWrap>
              {data.allSections.edges
                .filter(
                  ({
                    node: {
                      frontmatter: { part }
                    }
                  }) => part === frontmatter.part
                )
                .map(({ node: { frontmatter } }) => (
                  <SectionLink key={frontmatter.path} to={frontmatter.path}>
                    <SectionLinkInner>
                      <div>
                        <Romanize num={frontmatter.section} />{" "}
                        {frontmatter.title}
                        {hasLeft(
                          RequiredAction.PENDING_PEER_REVIEW,
                          frontmatter.part,
                          frontmatter.section
                        ) ? (
                          <PendingPeerReview>
                            <FormattedMessage id="quiz.pendingPeerReview" />
                          </PendingPeerReview>
                        ) : null}
                        {hasLeft(
                          RequiredAction.GIVE_PEER_REVIEW,
                          frontmatter.part,
                          frontmatter.section
                        ) ? (
                          <AwaitingPeerReview>
                            <FormattedMessage id="quiz.awaitingPeerReview" />
                          </AwaitingPeerReview>
                        ) : null}
                        {hasLeft(
                          RequiredAction.REJECTED,
                          frontmatter.part,
                          frontmatter.section
                        ) ? (
                          <RejectedPeerReview>
                            <FormattedMessage id="quiz.rejectedPeerReview" />
                          </RejectedPeerReview>
                        ) : null}
                        {hasLeft(
                          tagsWithChanged,
                          frontmatter.part,
                          frontmatter.section
                        ) ? (
                          <Unsubmitted>
                            <FormattedMessage id="quiz.unsubmittedAnswer" />
                          </Unsubmitted>
                        ) : null}
                      </div>
                      <div>
                        <SectionMeta>
                          <SectionCompletion
                            part={frontmatter.part}
                            section={frontmatter.section}
                          />
                        </SectionMeta>
                      </div>
                    </SectionLinkInner>
                  </SectionLink>
                ))}
            </div>
          )}
        </Box>
      </Card>
    );
  }
}

PartCard.propTypes = {
  data: PropTypes.shape().isRequired,
  frontmatter: PropTypes.shape().isRequired
};

const mapStateToProps = state => {
  return {
    tagsWithAwaitingConfirmation: getTagsWithAwaitingConfirmation(state),
    tagsWithAwaitingPeerReviewsGiven: getTagsWithAwaitingPeerReviewsGiven(
      state
    ),
    tagsWithRejected: getTagsWithRejected(state),
    tagsWithChanged: getTagsWithChanged(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(withAuthentication(injectCourseProgress(PartCard)));
