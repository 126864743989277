import Text from "./Text";

const TextF2 = Text.withComponent("h2");

TextF2.defaultProps = {
  fontSize: ["2rem", "3.5rem"],
  fontFamily: "TTNormsProBold",
  fontWeight: 600,
  lineHeight: 1.25,
  color: "#29264C"
};

export default TextF2;
