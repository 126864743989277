import axios from "axios";

import fetchPonyfill from "fetch-ponyfill";
const { fetch } = fetchPonyfill();

export const createMailchimpSubscriber = ({
  email,
  deadline = "self-paced",
  percentCompleted = 0,
  currentChapter = 0,
  lastSeen,
  marketing,
  language = "en",
  country = ""
}) => {
  axios({
    method: "POST",
    url: `https://us-central1-moocfi.cloudfunctions.net/createMailchimpSubscriber`,
    data: {
      email,
      deadline,
      percentCompleted,
      currentChapter,
      lastSeen: lastSeen || new Date().getTime(),
      marketing: marketing ? "allowed" : "disallowed",
      locale: language,
      country: country,
      courseSlug: "intro"
    },
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => console.log(response.data))
    .catch(error => console.log(error));
};

export const updateMailchimpSubscriber = email => {
  axios({
    method: "POST",
    url: `https://us-central1-moocfi.cloudfunctions.net/updateMailchimpSubscriber`,
    data: {
      email,
      course: "intro"
    },
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => response.data)
    .then(json => {});
};
