import Text from "./Text";

const TextF3 = Text.withComponent("h3");

TextF3.defaultProps = {
  fontSize: ["1.75rem", "2.25rem"],
  fontFamily: "TTNormsProBold",
  fontWeight: 200,
  lineHeight: 1.33,
  color: "#29264C"
};

export default TextF3;
