import React from "react";
import Link from "gatsby-link";

function WrongLocaleMessage({ currentLocale, userLocale }) {
  if (userLocale === "se") {
    return (
      <React.Fragment>
        Du tittar på kursen på ett annat språk än vad du anmält dig för. För att göra övningarna, gå till <Link to="/se">den svenska versionen</Link>,
        eller ändra språk i <Link to="/se/account">din profil</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "en") {
    return (
      <React.Fragment>
        You are viewing another version of the course than what you signed up
        to. To do the exercises go to the <Link to="/">global English version</Link>,
        or change the language in <Link to="/account">your profile</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "en-ie") {
    return (
      <React.Fragment>
        You are viewing another version of the course than what you signed up
        to. To do the exercises go to the <Link to="/en-ie">Ireland English version</Link>,
        or change the language in <Link to="/en-ie/account">your profile</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "en-lu") {
    return (
      <React.Fragment>
        You are viewing another version of the course than what you signed up
        to. To do the exercises go to the <Link to="/en-lu">Luxembourg English version</Link>,
        or change the language in <Link to="/en-lu/account">your profile</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "de") {
    return (
      <React.Fragment>
        Die jetzige Sprache der Webseite unterscheidet sich von der Sprache, in
        der du dich für den Kurs registriert hast. Um die Übungen zu machen,
        schalte auf die <Link to="/de">deutsche Sprachversion</Link> um, oder
        ändere deine Sprachwahl in <Link to="/de/account">deinem Profil</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "ee") {
    return (
      <React.Fragment>
        Vaatate kursust muus keeles kui selles, kuhu registreerusite. Harjutuste tegemiseks minge <Link to="/ee">eestikeelsesse versiooni</Link>{" "}
        või muutke <Link to="/ee/account">oma profiili</Link> keelt.
      </React.Fragment>
    );
  }
  if (userLocale === "lv") {
    return (
      <React.Fragment>
        Jūs skatāt kursu citā valodā, nevis tajā, kurā pierakstījāties. Lai
        izpildītu uzdevumus, dodieties uz{" "}
        <Link to="/lv">latviešu valodas versiju </Link>
        vai mainiet valodu <Link to="/lv/account">profilā</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "lt") {
    return (
      <React.Fragment>
        Kursą žiūrite kita kalba nei ta, į kurią užsiregistravote. Norėdami
        atlikti pratimus, eikite į{" "}
        <Link to="/lt">lietuvišką versiją</Link>{" "}
        arba pakeiskite kalbą <Link to="/lt/account">savo profilyje</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "fi") {
    return (
      <React.Fragment>
        Tarkastelet kurssisivua eri kielellä kuin millä ilmoittauduit.
        Tehdäksesi tehtäviä siirry{" "}
        <Link to="/fi">suomenkieliseen versioon</Link> tai vaihda kieltä{" "}
        <Link to="/fi/account">profiilissasi</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "fr") {
    return (
      <React.Fragment>
        Vous visualisez une autre version du cours que celle à laquelle vous
        vous êtes inscrit. Pour faire les exercices, allez à la{" "}
        <Link to="/fr">version française</Link>, ou changez la langue dans{" "}
        <Link to="/fr/account">votre profil</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "fr-be") {
    return (
      <React.Fragment>
        Vous visualisez une autre version du cours que celle à laquelle vous
        vous êtes inscrit. Pour faire les exercices, allez à la{" "}
        <Link to="/fr-be">version française (Belgique)</Link>, ou changez la langue dans{" "}
        <Link to="/fr-be/account">votre profil</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "ro") {
    return (
      <React.Fragment>
        Urmăriți cursul într-o altă limbă decât cea în care v-ați înscris. Pentru a face exercițiile, accesați{" "}
        <Link to="/ro">versiunea în limba română</Link> sau modificați limba în{" "}
        <Link to="/ro/account">profilul dumneavoastră</Link>.
      </React.Fragment>
    );
  }
  if (userLocale === "pl") {
    return (
      <React.Fragment>
        Przeglądasz kurs w innym języku niż ten, który wybrał podczas rejestracji. Aby wykonać ćwiczenia, należy przejść do{" "}
        <Link to="/pl">polskiej wersji</Link> lub zmienić język w{" "}
        <Link to="/pl/account">swoim profilu</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "el") {
    return (
      <React.Fragment>
        Βλέπετε το μάθημα σε άλλη γλώσσα από αυτήν για την οποία εγγραφήκατε
         προς το. Για να κάνετε τις ασκήσεις μεταβείτε στο <Link to="/el">Σουηδική έκδοση</Link>,
         ή αλλάξτε τη γλώσσα στο <Link to="/el/account">προφίλ σας</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "es") {
    return (
      <React.Fragment>
        Está viendo el curso en un idioma diferente al que se inscribió. Para hacer los ejercicios ve al <Link to="/es">versión en español</Link>, o cambiar el idioma en tu <Link to="/es/account">perfil</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "nl") {
    return (
      <React.Fragment>
        Je bekijkt een andere versie van de cursus dan waarvoor je je hebt aangemeld. Om de oefeningen te doen, ga naar de{" "}
        <Link to="/nl">nederlandse versie</Link> of verander de taal in je{" "}
        <Link to="/nl/account">profiel</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "sk") {
    return (
      <React.Fragment>
        Kurz si prehliadnite v rámci jazyka, než v ktorom ste sa zaregistrovali. Ak chcete vypĺňať cvičenia, prejdite na{" "}
        <Link to="/sk">slovenskú verziu</Link> alebo zmeňte jazyk vo{" "}
        <Link to="/sk/account">svojom profile</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "pt") {
    return (
      <React.Fragment>
        Está a consultar o curso numa língua diferente da que selecionou no momento
         da inscrição. Para resolver os exercícios, vá para a <Link to="/pt">Versão em português</Link>
         ou altere a língua no <Link to="/pt/account">seu perfil</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "no") {
    return (
      <React.Fragment>
        Du leser nå kurset på et annet språk enn det du registrerte deg for. For å gjøre oppgavene kan du gå tilbake til{" "}
        <Link to="/no">norsk versjon</Link> eller bytte språk i{" "}
        <Link to="/no/account">profilen din</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "da") {
    return (
      <React.Fragment>
        Kurset vises på et andet sprog end det, du har tilmeldt dig. Hvis du vil gennemføre øvelserne, skal du gå til <Link to="/da">version på dansk</Link> eller ændre sproget i <Link to="/da/account">din profil.</Link>
      </React.Fragment>
    );
  }

  if (userLocale === "de-at") {
    return (
      <React.Fragment>
        Die jetzige Sprache der Webseite unterscheidet sich von der Sprache, in
        der du dich für den Kurs registriert hast. Um die Übungen zu machen,
        schalte auf die <Link to="/de-at">österreichisch-deutsche Sprachversion</Link> um, oder
        ändere deine Sprachwahl in <Link to="/de-at/account">deinem Profil.</Link>
      </React.Fragment>
    );
  }
  if (userLocale === "sl") {
    return (
      <React.Fragment>
        Tečaj si ogledujete v drugem jeziku, kot je bil vaš jezik registracije. Za izpolnitev naloge izberite{" "}
        <Link to="/sl">slovenska različico</Link> ali spremenite jezik v{" "}
        <Link to="/sl/account">svojem profilu.</Link>
      </React.Fragment>
    );
  }

  if (userLocale === "bg") {
    return (
      <React.Fragment>
        Преглеждате страницата на курса на език, различен от този, който сте регистрирали. За да завършите задачите, отидете на <Link to="/bg">българската версия</Link>, или да промените езика на <Link to="/bg/account">профила</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "cs") {
    return (
      <React.Fragment>
        Kurz prohlížíte v jiném jazyce, než ve kterém jste se zaregistrovali. Chcete-li provést cvičení, přejděte na <Link to="/cs">českou verzi</Link> nebo změňte jazyk v <Link to="/cs/account">vašeho profilu.</Link>
      </React.Fragment>
    );
  }

  if (userLocale === "mt") {
    return (
      <React.Fragment>
        Qed issegwi l-kors b’lingwa differenti minn dik li rreġistrajt għaliha. Biex tagħmel l-eżerċizzji, ara{" "}
        <Link to="/mt">l-verżjoni bil-Malti</Link>, jew ibdel il-lingwa{" "}
        <Link to="/mt/account">fil-profil</Link> tiegħek.
      </React.Fragment>
    );
  }

  if (userLocale === "nl-be") {
    return (
      <React.Fragment>
        U bekijkt een andere versie van de cursus dan waarvoor u zich heeft aangemeld. Om de oefeningen te doen, ga naar {" "}
        <Link to="/nl-be">Nederlandse (België) versie</Link>
        , oof verander taal in{" "}
        <Link to="/nl-be/account">je profiel.</Link>
      </React.Fragment>
    );
  }

  if (userLocale === "it") {
    return (
      <React.Fragment>
        Stai visualizzando il corso in un’altra lingua rispetto a quella a cui
        ti sei registrato. Per eseguire le attività, vai a{" "}
        <Link to="/it">alla versione italiana</Link>, o cambia la lingua{" "}
        <Link to="/it/account">nel tuo profilo.</Link>
      </React.Fragment>
    );
  }

  if (userLocale === "ga") {
    return (
      <React.Fragment>
        Tá tú ag féachaint ar an gcúrsa i dteanga eile seachas an teanga inar chláraigh tú. Chun na cleachtaí a dhéanamh, téigh chuig an{" "}
        <Link to="/ga">leagan Gaeilge</Link>, é sin nó athraigh an teanga i{" "}
        <Link to="/ga/account">do phróifíl</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "hr") {
    return (
      <React.Fragment>
        Ova inačica tečaja nije na jeziku na kojem ste se prijavili. Za rješavanje zadataka morate odabrati <Link to="/hr">inačicu na hrvatskom</Link>, ili promijeniti jezik u <Link to="/hr/account">svojem profilu.</Link>
      </React.Fragment>
    );
  }
  if (userLocale === "is") {
    return (
      <React.Fragment>
        Þú hefur opnað námskeiðið á öðru tungumáli en þú skráðir þig fyrir. Til þess að geta leyst æfingarnar þarftu annaðhvort að velja{" "}
        <Link to="/is">íslensku útgáfuna</Link> eða skipta um tungumál á{" "}
        <Link to="/is/account">notandasíðunni þinni</Link>.
      </React.Fragment>
    );
  }

  if (userLocale === "ga") {
    return (
      <React.Fragment>
        Tá tú ag féachaint ar an gcúrsa i dteanga eile seachas an teanga inar chláraigh tú. Chun na cleachtaí a dhéanamh, téigh chuig an{" "}
        <Link to="/ga">leagan Gaeilge</Link>, é sin nó athraigh an teanga i{" "}
        <Link to="/ga/account">do phróifíl</Link>.
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      You are viewing the course in another language than what you signed up to.
      To do the exercises go to the <Link to="/">English version</Link>, or
      change the language in <Link to="/account">your profile.</Link>
    </React.Fragment>
  );
}

export default WrongLocaleMessage;
