import React, { useState, useEffect } from "react";
import StyledLink from "../StyledLink";
import { themeGet } from "styled-system";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { userDetails } from "../../services/moocfi";

import Stats from "../Stats";
import { MediumContainer } from "../Grid";
import Box from "../Box";
import TextF1 from "../TextF1";
import TextF4 from "../TextF4";
import TextF5 from "../TextF5";
import dashboardCompletedBackground from "../../images/dashboardCompletedBackground.svg";
import CertificateAvailability from "../CertificateAvailability";
import { getStats, isInitializing } from "../../state/stats/selectors";
import { isCompleted } from "../../state/courseState/selectors";

import WrongLocaleMessage from "../WrongLocaleMessage";

import styled from "styled-components";

import { injectCourseProgress } from "moocfi-quizzes";
import withErrorBoundary from "../../utilities/withErrorBoundary";
import { updateMailchimpSubscriber } from "../../services/zapier";

const Heading = styled(TextF1)`
  text-align: center;
  color: white;
  text-transform:uppercase;
`;

const StyledF4 = styled(TextF4)`
  text-align: center;
  color: white;
  margin: 0;
  font-family: ${props => props.locale === "el" ? "tt-norms-pro-regular" : themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const ButtonLogin = styled(StyledLink)`
  color: white;
  border: 2px solid #19ca94;
  display: block;
  font-size: 0.875rem;
  border-radius: 90px;
  padding: 0.75rem 1.5rem;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.2s ease;
  :hover {
    color: white;
    background: #19ca94;
    border: 2px solid #19ca94;
  }
  @media screen and (min-width: 38em) {
    font-size: 1rem;
    padding: 1rem 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media screen and (min-width: 72em) {
    margin-top: 0;
  }
  :hover {
  }
`;

const SwitchLanguageParagraph = styled(TextF5)`
  color: white;
  text-align: center;
  a {
    color: white;
    text-decoration: underline;
  }
`;

const ButtonSignUp = styled(StyledLink)`
  color: white;
  background: #19ca94;
  border: 2px solid #19ca94;
  display: block;
  font-size: 0.875rem;
  border-radius: 90px;
  padding: 0.75rem 1.5rem;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.2s ease;
  text-align: center;
  :hover {
    background: #11ab7c;
    border: 2px solid #11ab7c;
  }
  @media screen and (min-width: 38em) {
    padding: 1rem 3rem;
    font-size: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media screen and (min-width: 72em) {
    margin-top: 0;
  }
`;

const CompletedContainer = styled(Box)`
  margin: 0 auto;
  max-width: 1500px;
  position: relative;
  @media screen and (min-width: 38em) {
    ${MediumContainer} {
      position: relative;
      z-index: 5;
    }
    :before {
      content: "";
      background: url(${dashboardCompletedBackground});
      background-size: cover;
      background-position: bottom center;
      position: absolute;
      width: 110%;
      left: -5%;
      height: 100%;
      top: 0;
    }
  }
`;

const CourseHeader = props => {
  const [user, setUser] = useState(null)

  const {
    loggedIn,
    matchingLocale,
    intl,
    userLocale,
    courseProgressData
  } = props;

  useEffect(() => {
    if (loggedIn) {
      fetchProfile()
    }
  }, [])

  const fetchProfile = () => {
    userDetails().then(user => {
      setUser(user)
    }).catch(error => {
      console.log("Failed to load profile")
      setUser(null)
    })
  }

  const completed = courseProgressData && courseProgressData.completed;

  // Send the completed email message if the user has completed the course
  useEffect(() => {
    if (!user?.email) {
      return
    }
    if (completed) {
      try {
        updateMailchimpSubscriber(user.email)
      } catch (e) {
        console.warn(e)
      }
    }
  }, [completed, user?.email])

  return loggedIn && !completed && matchingLocale ? (
    <MediumContainer py={[4]}>
      <Box mt={6}>
        <Heading m={0}>
          <FormattedMessage id="dashboard.title" />
        </Heading>
      </Box>
      <Box my={4}>
        <Stats />
      </Box>
    </MediumContainer>
  ) : loggedIn && completed && matchingLocale ? (
    <CompletedContainer>
      <MediumContainer py={[4]}>
        <Box mt={6}>
          <Heading mb={3} mt={0}>
            <FormattedMessage id="dashboard.congratulations.title" />
          </Heading>
          <StyledF4 locale={intl.locale}>
            <FormattedMessage id="dashboard.congratulations.message" />
          </StyledF4>
          <Box
            display="flex"
            flexDirection={["column", "row", "row"]}
            justifyContent="center"
            alignItems="center"
            mt={4}
          >
            {user && <CertificateAvailability user={props.user} />}
            {/*<ButtonSignUp to={intl.formatMessage({ id: "routes.ACCOUNT" })}>
              <FormattedMessage id="dashboard.congratulations.downloadCertificate" />
              </ButtonSignUp>*/}
            {/* <ButtonSignUp to="/">Apply for credits</ButtonSignUp> */}
          </Box>
        </Box>
        <Box my={4}>
          <Stats />
        </Box>
      </MediumContainer>
    </CompletedContainer>
  ) : loggedIn && !matchingLocale ? (
    <MediumContainer py={[4]}>
      <Box mt={6}>
        <Heading m={0}>
          <FormattedMessage id="dashboard.title" />
        </Heading>
        <SwitchLanguageParagraph>
          <WrongLocaleMessage
            userLocale={userLocale}
            currentLocale={intl.locale}
          />
        </SwitchLanguageParagraph>
      </Box>
    </MediumContainer>
  ) : (
    <MediumContainer py={[4]}>
      <Box mt={6}>
        <Heading mb={3} mt={0}>
          <FormattedMessage id="dashboard.notLoggedIn.title" />
        </Heading>
        <StyledF4 locale={intl.locale}>
          <FormattedMessage id="dashboard.notLoggedIn.message" />
        </StyledF4>
        <Box display="flex" justifyContent="center" mt={4}>
          <ButtonLogin to={intl.formatMessage({ id: "routes.SIGN_IN" })}>
            <FormattedMessage id="globals.logIn" />
          </ButtonLogin>
          <ButtonSignUp to={intl.formatMessage({ id: "routes.SIGN_UP" })}>
            <FormattedMessage id="globals.signUp" />
          </ButtonSignUp>
        </Box>
      </Box>
    </MediumContainer>
  );
};

const mapStateProps = state => {
  return {
    stats: getStats(state),
    initializing: isInitializing(state),
    completed: isCompleted(state)
  };
};

export default withErrorBoundary(connect(
  mapStateProps,
  null
)(injectIntl(injectCourseProgress(CourseHeader))));
