import Text from "./Text";

const TextF1 = Text.withComponent("h1");

TextF1.defaultProps = {
  fontSize: ["2.5rem", "4rem"],
  fontFamily: "TTNormsProBold",
  fontWeight: 600,
  lineHeight: 1.25,
  color: "#29264C"
};

export default TextF1;
